import { Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { GridCellParams } from "@mui/x-data-grid";
import { ICouponGroup, IOrderBy, XCondition, XConditionOperator } from "amp";
import { keyBy } from "lodash";
import { useCallback, useState } from "react";
import { Actions } from "src/components/Actions";
import { DynamicTablePage } from "src/components/dynamic-table/DynamicTablePage";
import { TitleLink } from "src/components/TitleLink";
import { useRefreshQueryContext } from "src/contexts/RefreshQueryContext";
import { useIsAdmin } from "src/hooks/permissions/useIsAdmin";
import { useDynamicTableSearch } from "src/hooks/useDynamicTableSearch";
import { useFormLinks } from "src/hooks/useFormLinks";
import { DynamicTableUtility } from "src/utils/dynamicTable";
import { CouponRoutes } from "../../coupons.routes";
import { CouponGroupService } from "../coupon-group.service";
import { CouponGroupRoutes } from "../coupon-group-routes";
import { formatDate } from "src/utils/formatDate";

interface ICouponGroupList {
	id: number;
	name: string;
	description: string;
	numberOfCoupons: number;
	createdOn: string;
}

const sortOptions: { label: string; value: IOrderBy[] }[] = [
	{
		label: "Name (A-Z)",
		value: [{ column: "name", direction: "ASC" }],
	},
	{
		label: "Name (Z-A)",
		value: [{ column: "name", direction: "DESC" }],
	},
	{
		label: "Created (Oldest - Newest)",
		value: [{ column: "created", direction: "ASC" }],
	},
	{
		label: "Created (Newest - Oldest)",
		value: [{ column: "created", direction: "DESC" }],
	},
];

const activeOptions: { label: string; value: boolean}[] = [
	{
		label: "Active",
		value: true,
	},
	{
		label: "Inactive",
		value: false,
	},
];

const _transformDataGroup = async (couponGroups: ICouponGroup[]): Promise<ICouponGroupList[]> => {
	const couponGroupIds = couponGroups.map((couponGroup) => couponGroup.id);
	const { result } = await CouponGroupService.getCouponCountsByCouponGroup(couponGroupIds);
	const countDict = keyBy(result, (item) => item.couponGroupId);
	return couponGroups.map((couponGroup) => ({
		id: couponGroup.id,
		name: couponGroup.name,
		description: couponGroup.description,
		numberOfCoupons: countDict[couponGroup.id]?.couponCount ?? 0,
		createdOn: formatDate(couponGroup.created),
	}));
};

const _titleRenderer = ({ value, row }: GridCellParams): JSX.Element => {
	const { editUrl } = useFormLinks(row.id, CouponGroupRoutes);

	return <TitleLink title={value as string} to={editUrl} />;
};

const _actionsRenderer = ({ row }: GridCellParams): JSX.Element => {
	const { editUrl } = useFormLinks(row.id, CouponGroupRoutes);

	return <Actions editTo={editUrl} />;
};

const { ITEM_NAME, ITEM_NAME_PLURAL, baseQuery } = CouponGroupService;

const _COLUMNS = DynamicTableUtility.createColumns([
	{ name: "Coupon Group", field: "name", flex: 1, cell: _titleRenderer },
	{ name: "Description", field: "description", flex: 1.5 },
	{ name: "# Coupons", field: "numberOfCoupons", width: 150 },
	{ name: "Created On", field: "createdOn", width: 250 },
	{ name: "Actions", field: "actions", width: 120, cell: _actionsRenderer },
]);

export const CouponGroupList = () => {
	const [selectedSortOption, setSelectedSortOption] = useState<IOrderBy[]>(
		sortOptions[0].value,
	);
	const { refreshQuery } = useRefreshQueryContext();

	const [active, setActive] = useState(true);

	const isAdmin = useIsAdmin();

	const getSearchWithCouponGroupCondition = useCallback(
		(search: string) => _getSearchCondition(search, active),
		[active],
	);

	const { inputRef, condition, setSearch } = useDynamicTableSearch(
		ITEM_NAME_PLURAL,
		getSearchWithCouponGroupCondition,
	);

	return (
		<DynamicTablePage
			itemName={ITEM_NAME}
			pageName={ITEM_NAME_PLURAL}
			createRoute={CouponRoutes.CREATE_ROUTE}
			disableCreate={!isAdmin}
			tableName="couponGroup"
			baseQuery={baseQuery}
			orderBys={selectedSortOption}
			columns={_COLUMNS}
			condition={condition}
			transformDataGroup={_transformDataGroup}
			hideHeader
			customRefreshQuery={refreshQuery}
		>
			<Grid
				container
				style={{ flexDirection: "row", alignItems: "flex-end", margin: 12 }}
			>
				<Grid xs={12} md={6} spacing={3} container direction="row">
					<Grid item>
						<TextField
							style={{ width: 320 }}
							inputRef={inputRef}
							label="Search"
							onChange={({ target: { value } }) => setSearch(value ?? "")}
							variant="outlined"
							fullWidth
						/>
					</Grid>
					<Grid item>
						<Select
							labelId="active"
							label="Active"
							name="active"
							onChange={(event: any) => {
								setActive(event.target.value);
							}}
							value={active}
							variant="outlined"
							style={{ width: 300 }}
						>
							{activeOptions.map((option) => (
								<MenuItem key={option.label} value={option.value as any}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Grid>
				</Grid>
				<Grid
					container
					justifyContent="flex-end"
					spacing={2}
					item
					md={6}
					xs={12}
				>
					<Grid item>
						<InputLabel id="sortBy">Sort</InputLabel>
						<Select
							labelId="sortBy"
							name="sort"
							onChange={(event: any) => {
								setSelectedSortOption(event.target.value);
							}}
							value={selectedSortOption ?? sortOptions[0].value}
							variant="outlined"
							style={{ width: 300 }}
						>
							{sortOptions.map((option) => (
								<MenuItem key={option.label} value={option.value as any}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Grid>
				</Grid>
			</Grid>
		</DynamicTablePage>
	);
};

function _getSearchCondition(
	search: string,
	active: boolean,
): XCondition | undefined {
	const right = search?.toLowerCase()?.trim();
	const conditions: XCondition[] = [];

	if (right) {
		conditions.push({
			operator: "OR",
			conditions: [
				{
					left: { column: "name", transforms: ["LOWERCASE"] },
					operator: "LIKE",
					right,
				},
				{
					left: { column: "description", transforms: ["LOWERCASE"] },
					operator: "LIKE",
					right,
				},
			]
		});
	}
	conditions.push({
		left: { column: "is_active" },
		operator: "EQ" as XConditionOperator,
		right: active,
	});
	return {
		operator: "AND",
		conditions,
	};
}