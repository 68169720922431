import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useState } from "react";

interface Props<T> {
	items: T[];
	getItemValue: (item: T) => string | number;
	getItemDisplayValue: (item: T) => string;
	onItemSelected: (itemValue?: string | number) => void;
	label: string;
	clearable?: boolean;
}

export function DynamicTableSelectInput<T>({
	items = [],
	getItemValue,
	getItemDisplayValue,
	onItemSelected,
	label,
	clearable,
}: Props<T>) {
	const [selectedItem, setSelectedItem] = useState("clear");
	return (
		<FormControl style={{ width: 200 }} variant="outlined">
			<InputLabel id={label} >
				{label}
			</InputLabel>
			<Select
				labelId={label}
				name={label}
				label={label}
				onChange={(event: any) => {
					const selection = event.target.value;
					if (selection === "clear") {
						setSelectedItem("clear");
						onItemSelected(undefined);
					} else {
						setSelectedItem(selection);
						onItemSelected(selection);
					}
				}}
				value={selectedItem}
				variant="outlined"
			>
				{clearable && <MenuItem key="clear" value="clear">All</MenuItem>}
				{items.map((item) => (
					<MenuItem key={getItemValue(item)} value={getItemValue(item)}>
						{getItemDisplayValue(item)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
