import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useTableStateKey = (key: string) => {
	const location = useLocation();
	return `${key}--${location.pathname}`;
};

export const useGetPersistedState = <T extends unknown>(key, defaultState: T) => {
	const tableStateKey = useTableStateKey(key);
		
	return useMemo(() => {
		const sessionState = window.sessionStorage.getItem(tableStateKey);
		const newState: T = sessionState ? JSON.parse(sessionState) : null;
		return newState ?? defaultState;
	}, [tableStateKey]);
};

const usePersistedState = <T extends unknown>(key: string, state: T): T => {
	const [persistedState, setPersistedState] = useState<T>(state);
	const tableStateKey = useTableStateKey(key);

	const persistedStateInitial = useGetPersistedState(key, state);
	
	useEffect(() => {
		setPersistedState(persistedStateInitial);
	}, [persistedStateInitial]);

	const updatePersistedState = useCallback(() => {
		// Set state in local storage
		window.sessionStorage.setItem(tableStateKey, JSON.stringify(state));
	}, [state, tableStateKey]);

	useEffect(() => {
		// Remove old event listener, if any
		window.removeEventListener("beforeunload", updatePersistedState);
		// Set persisted state on browser navigation
		window.addEventListener("beforeunload", updatePersistedState);
		// Remove event listener on component dismount
		return () => {
			window.removeEventListener("beforeunload", updatePersistedState);
			// Set persisted state on component dismount
			updatePersistedState();
		};
	}, [updatePersistedState]);

	return persistedState;
};

export default usePersistedState;
