import { GridCellParams, GridColDef } from "@mui/x-data-grid";

function cellWithTitle(cell: GridCellParams): JSX.Element {
	return (
		<div 
			title={String(cell.value)}
			style={{
				width: "100%",
				overflow: "hidden",
				whiteSpace: "nowrap",
				textOverflow: "ellipsis",
			}}
		>
			{ cell.value }
		</div>
	);
}

function createColumns(
	columns: {
		name: string,
		field: string,
		className?: string,
		width?: number,
		flex?: number,
		hide?: boolean,
		cell?: (params: GridCellParams) => JSX.Element,
	}[],
): GridColDef[] {
	return columns.map(({ name, field, className, width, flex, hide, cell }) => ({
		headerName: name,
		field,
		headerClassName: className,
		hide: hide ?? false,
		sortable: false,
		filterable: false,
		width,
		flex,
		renderCell: cell ?? cellWithTitle,
	}));
}

export const DynamicTableUtility = {
	createColumns,
	cellWithTitle,
};