import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

export const useDataGridWidth = (bulkActionEnabled: boolean) => {
	const dataGridRef = useRef<HTMLDivElement>(null);
	const [dataGridWidth, setDataGridWidth] = useState(0);

	const updateDataGridWidth = useCallback(
		debounce(() => {
			if (dataGridRef.current && bulkActionEnabled) {
			setDataGridWidth(dataGridRef.current.clientWidth);
			}
		}, 100),
		[bulkActionEnabled]
	);

	useEffect(() => {
		if (!dataGridRef.current) {
			return;
		}
		const resizeObserver = new ResizeObserver(() => {
			updateDataGridWidth();
		});
		// Observe data grid width
		if (bulkActionEnabled) {
			resizeObserver.observe(dataGridRef.current);
		}
		// Clean up
		return () => {
			if (bulkActionEnabled && dataGridRef.current) {
				resizeObserver.unobserve(dataGridRef.current);
			}
		};
	}, [dataGridRef.current]);

	return {
		dataGridRef,
		dataGridWidth,
	};
};
