import {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useCallback,
	useContext
} from 'react';
import { useStateWithMounted } from 'src/utils/useStateWithMounted';

export interface IRefreshQueryContext {
	refreshQuery: number;
	setRefreshQuery: Dispatch<SetStateAction<number>>;
	refresh: () => void;
}

const RefreshQueryContext = createContext<IRefreshQueryContext | undefined>(undefined);

export const useRefreshQueryContext = () => {
	const refreshQueryContext = useContext(RefreshQueryContext);
	if (!refreshQueryContext) {
		throw new Error("Refresh Query Context not provided");
	}
	return refreshQueryContext;
}

export const RefreshQueryProvider = ({ children }: { children: ReactNode }) => {
	const [refreshQuery, setRefreshQuery] = useStateWithMounted(1);
	const refresh = useCallback(() => setRefreshQuery((prev) => prev + 1), []);

	return (
		<RefreshQueryContext.Provider value={{
			refreshQuery,
			setRefreshQuery,
			refresh,
		}}>
			{children}
		</RefreshQueryContext.Provider>
	);
};
