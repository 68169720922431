import { useState, useRef, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import usePersistedState, { useGetPersistedState } from "./usePersistedState";
import { XCondition } from "amp";

export function useDynamicTableSearch(
	persistedStateKey: string,
	getSearchCondition: (searchValue: string) => XCondition | undefined,
) {
	const { search: searchRestored } = useGetPersistedState(persistedStateKey, { search: "" });
	const [search, setSearch] = useState(searchRestored);
	
	const [condition, setCondition] = useState<XCondition | undefined>(() => getSearchCondition(searchRestored));

	const inputRef = useRef<HTMLInputElement>();
	const initialSet = useRef(false);

	usePersistedState(persistedStateKey, { search });

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.value = searchRestored ?? "";
		}
	}, [searchRestored]);

	useEffect(() => {
		if (initialSet.current) {
			const _condition = getSearchCondition(search);
			setCondition(_condition);
		} else {
			initialSet.current = true;
		}
	}, [search, getSearchCondition]);

	const _setSearch = useCallback(
		debounce((value) => {
			setSearch(value);
		}, 100),
	 []);

	return {
		setSearch: _setSearch,
		search,
		inputRef,
		condition,
	};
}