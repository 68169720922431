import React, { ChangeEvent } from "react";
import { Checkbox, ListItemText, MenuItem } from "@material-ui/core";
import MultiselectInput, { MultiselectProps } from "./MultiselectInput";

const ALL_VALUE = "066ade53-51ce-410f-abfd-cdfc384f7e31";

export interface MultiselectWithAllProps<T, K> extends Omit<MultiselectProps<T, K>, "renderValue"> {
	isAllChecked?: (selectedOptions: K[]) => boolean,
	allLabel?: string;
}
export function MultiselectWithAll<T, K>({
	isAllChecked = (options) => options.length === 0,
	allLabel = "All",
	getLabel,
	onChange,
	selections,
	...rest
}: MultiselectWithAllProps<T, K>) {
	function handleOnChange(event: ChangeEvent<{ name?: string; value: unknown }>): void {
		const eventValues = event.target.value as (K | typeof ALL_VALUE)[];
		const hasAllOption = eventValues.includes(ALL_VALUE);
		const valuesWithoutAll = eventValues.filter((eventValue) => eventValue !== ALL_VALUE) as K[];
		event.target.value = hasAllOption ? [] : valuesWithoutAll;
		onChange(event);
	}

	function renderValue(selectedOptions: T[]): string {
		const selectionLabels = selectedOptions.map((selectedOption) => {
			return getLabel(selectedOption);
		});

		const allChecked = isAllChecked(selections);
		if (allChecked) {
			selectionLabels.unshift(allLabel);
		}

		return selectionLabels.join(", ")
	}

	return (
		<MultiselectInput
			getLabel={(option) => option ? getLabel(option) : ""}
			onChange={handleOnChange}
			selections={selections}
			renderValue={renderValue}
			{...rest}
		>
			<MenuItem value={ALL_VALUE}>
				<Checkbox checked={isAllChecked(selections)} />
				<ListItemText primary={allLabel} />
			</MenuItem>
		</MultiselectInput>
)}