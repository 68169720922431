import { Grid, IconButton, SvgIcon, Tooltip } from "@material-ui/core";
import { XDynamicSqlName } from "amp";
import { Copy, Edit, Eye } from "react-feather";
import { Link as RouterLink } from "react-router-dom";
import { ExportToCSVButton, ExportToCSVProps } from "./ExportToCsv";

interface Props<T extends object, K extends XDynamicSqlName> {
	editTo?: string;
	copyTo?: string;
	viewTo?: string;
	exportData?: Omit<ExportToCSVProps<T, K>, "type">;
	openInNewTab?: boolean
}
export function Actions<T extends object, K extends XDynamicSqlName>({
	editTo,
	copyTo,
	viewTo,
	exportData,
	openInNewTab,
}: Props<T, K>) {
	return (
		<Grid container>
			{editTo && (
    			<Grid item>
					<Tooltip title="Edit">
						<IconButton component={RouterLink}
							to={editTo}
							target={openInNewTab ? "_blank" : undefined}
						>
							<SvgIcon fontSize="small">
								<Edit />
							</SvgIcon>
						</IconButton>
					</Tooltip>
				</Grid>
  			)}
			{copyTo && (
    			<Grid item>
					<Tooltip title="Copy">
						<IconButton component={RouterLink}
							to={copyTo}
							target={openInNewTab ? "_blank" : undefined}
						>
							<SvgIcon fontSize="small">
								<Copy />
							</SvgIcon>
						</IconButton>
					</Tooltip>
				</Grid>
  			)}
			{viewTo && (
    			<Grid item>
					<Tooltip title="View">
						<IconButton component={RouterLink}
							to={viewTo}
							target={openInNewTab ? "_blank" : undefined}
						>
							<SvgIcon fontSize="small">
								<Eye />
							</SvgIcon>
						</IconButton>
					</Tooltip>
				</Grid>
  			)}
			{exportData && (
    			<Tooltip title="Export">
					<Grid item>
						<ExportToCSVButton 
							{...exportData} 
							type="Action_Item" />
					</Grid>
				</Tooltip>
  			)}
		</Grid>
	);
}
