import {
	EStripeCouponDuration,
	ICoupon,
	ICouponGroup,
	IOrderBy,
	XCondition,
	XConditionOperator,
	XOptional,
} from "amp";
import CouponsService from "./coupons.service";
import {
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import { DynamicTablePage } from "src/components/dynamic-table/DynamicTablePage";
import { DynamicTableUtility } from "src/utils/dynamicTable";
import { useFormLinks } from "src/hooks/useFormLinks";
import { CouponRoutes } from "../coupon-management/coupons.routes";
import { GridCellParams } from "@mui/x-data-grid";
import { TitleLink } from "src/components/TitleLink";
import { Actions } from "src/components/Actions";
import { useIsAdmin } from "src/hooks/permissions/useIsAdmin";
import { useCallback, useState } from "react";
import { useDynamicTableSearch } from "src/hooks/useDynamicTableSearch";
import { DynamicTableSelectInput } from "src/components/dynamic-table/components/DynamicTableSelectInput";
import { useFetchDataDynamic } from "src/hooks/useFetchDataDynamic";
import { useRefreshQueryContext } from "src/contexts/RefreshQueryContext";

interface IProps {
	coupons: ICoupon[];
	loading: boolean;
}

interface ICouponList {
	id: number;
	name: string;
	terms: string;
	numberOfPromoCodes: number;
	couponGroup: string;
	createdOn: Date;
}

const sortOptions: { label: string; value: IOrderBy[] }[] = [
	{
		label: "Name (A-Z)",
		value: [{ column: "name", direction: "ASC" }],
	},
	{
		label: "Name (Z-A)",
		value: [{ column: "name", direction: "DESC" }],
	},
];

const _transformData = (coupon: ICoupon): ICouponList => {
	return {
		id: coupon.id,
		name: coupon.name,
		terms: getAmountOrPercentOffString(coupon),
		numberOfPromoCodes: coupon.couponPrices?.length,
		couponGroup: coupon.couponGroup?.name,
		createdOn: coupon.created,
	};
};

const _titleRenderer = ({ value, row }: GridCellParams): JSX.Element => {
	const { editUrl } = useFormLinks(row.id, CouponRoutes);

	return <TitleLink title={value as string} to={editUrl} />;
};

const _actionsRenderer = ({ row }: GridCellParams): JSX.Element => {
	const { editUrl, copyUrl } = useFormLinks(row.id, CouponRoutes);

	return <Actions editTo={editUrl} copyTo={copyUrl} />;
};

const getDurationString = (coupon: ICoupon) => {
	if (coupon.duration === EStripeCouponDuration.Once) {
		return "once";
	}
	if (coupon.duration === EStripeCouponDuration.Repeating) {
		return `for ${coupon.duration_in_months} months`;
	}
	if (coupon.duration === EStripeCouponDuration.Forever) {
		return "forever";
	}
};

const getAmountOrPercentOffString = (coupon: ICoupon) => {
	if (coupon.amount_off) {
		return `$${coupon.amount_off} off ${getDurationString(coupon)}`;
	}
	if (coupon.percent_off) {
		return `${coupon.percent_off}% off ${getDurationString(coupon)}`;
	}
};

const { ITEM_NAME, ITEM_NAME_PLURAL, baseQuery, breadcrumbs } = CouponsService;

const _COLUMNS = DynamicTableUtility.createColumns([
	{ name: "Name", field: "name", flex: 1.5, cell: _titleRenderer },
	{ name: "Terms", field: "terms", flex: 1 },
	{ name: "# Promo Codes", field: "numberOfPromoCodes", flex: 1 },
	{ name: "Coupon Group", field: "couponGroup", flex: 1 },
	{ name: "Created On", field: "createdOn", flex: 1 },
	{ name: "Actions", field: "actions", width: 120, cell: _actionsRenderer },
]);

const getCouponGroupCondition = (
	couponGroup: string,
): XCondition | undefined => {
	if (!couponGroup) {
		return undefined;
	}
	return {
		left: { column: "coupon_group_id" },
		operator: "EQ",
		right: couponGroup,
	};
};

const CouponsTablePage = () => {
	const [selectedSortOption, setSelectedSortOption] = useState<IOrderBy[]>(
		sortOptions[0].value,
	);
	const { refreshQuery } = useRefreshQueryContext();

	const [selectedCouponGroup, setSelectedCouponGroup] =
		useState<XOptional<string>>(undefined);

	const isAdmin = useIsAdmin();

	const getSearchWithCouponGroupCondition = useCallback(
		(search: string) => _getSearchCondition(search, selectedCouponGroup),
		[selectedCouponGroup],
	);

	const { inputRef, condition, setSearch } = useDynamicTableSearch(
		ITEM_NAME_PLURAL,
		getSearchWithCouponGroupCondition,
	);

	const { data: couponGroups } = useFetchDataDynamic("couponGroup", {
		columns: ["name", "id"],
		orderBys: [
			{ column: "name", direction: "ASC" },
			{ column: "id", direction: "ASC" },
		],
	});

	return (
		<DynamicTablePage
			itemName={ITEM_NAME}
			pageName={ITEM_NAME_PLURAL}
			createRoute={CouponRoutes.CREATE_ROUTE}
			disableCreate={!isAdmin}
			tableName="coupons"
			baseQuery={baseQuery}
			orderBys={selectedSortOption}
			columns={_COLUMNS}
			condition={condition}
			transformData={_transformData}
			hideHeader
			customRefreshQuery={refreshQuery}
		>
			<Grid
				container
				style={{ flexDirection: "row", alignItems: "flex-end", margin: 12 }}
			>
				<Grid xs={12} md={6} spacing={3} container direction="row">
					<Grid item>
						<TextField
							style={{ width: 320 }}
							inputRef={inputRef}
							label="Search"
							onChange={({ target: { value } }) => setSearch(value ?? "")}
							variant="outlined"
							fullWidth
						/>
					</Grid>
					<Grid item>
						<DynamicTableSelectInput<ICouponGroup>
							label="Coupon Group"
							items={couponGroups ?? []}
							onItemSelected={(couponGroupId: string) =>
								setSelectedCouponGroup(couponGroupId)
							}
							getItemValue={(item) => item.id}
							getItemDisplayValue={(item) => item.name}
							clearable
						/>
					</Grid>
				</Grid>
				<Grid
					container
					justifyContent="flex-end"
					spacing={2}
					item
					md={6}
					xs={12}
				>
					<Grid item>
						<InputLabel id="sortBy">Sort</InputLabel>
						<Select
							labelId="sortBy"
							name="sort"
							onChange={(event: any) => {
								setSelectedSortOption(event.target.value);
							}}
							value={selectedSortOption ?? sortOptions[0].value}
							variant="outlined"
							style={{ width: 300 }}
						>
							{sortOptions.map((option) => (
								<MenuItem key={option.label} value={option.value as any}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</Grid>
				</Grid>
			</Grid>
		</DynamicTablePage>
	);
};

function _getSearchCondition(
	search: string,
	couponGroupId: XOptional<string>,
): XCondition | undefined {
	const right = search?.toLowerCase()?.trim();
	const conditions: XCondition[] = right
		? [
				{
					left: { column: "name", transforms: ["LOWERCASE"] },
					operator: "LIKE",
					right,
				},
		  ]
		: [{ left: 1, operator: "EQ", right: 1 }];
	if (couponGroupId) {
		conditions.push(getCouponGroupCondition(couponGroupId));
	}
	conditions.push({
		left: { column: "is_deleted" },
		operator: "EQ" as XConditionOperator,
		right: false,
	});
	return {
		operator: "AND",
		conditions,
	};
}

export default CouponsTablePage;
