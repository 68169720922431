export function renderConditionally<T>(
  condition: T | boolean | string | number | null | undefined | object,
  content: ((value: NonNullable<T>) => React.ReactNode) | React.ReactNode,
  falsyContent?: React.ReactNode
): React.ReactNode {
	if (!condition) return falsyContent ?? null;
  
	// Use function type to allow type assertion of conditon if needed in content
	if (typeof content === 'function') {
		return content(condition as NonNullable<T>);
	}
  
  	return content;
}