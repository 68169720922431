import {
	EEndpointType,
	ICoupon,
	ICreateCouponRequest,
	IUpdateCouponRequest,
	XCreateCouponResponse,
	XDeleteCouponResponse,
} from "amp";
import axios from "src/utils/axios";
import { CouponRoutes } from "../coupon-management/coupons.routes";
import { Breadcrumb } from "src/components/Header";
import { IDynamicQueryWithRefresh } from "src/components/dynamic-table/DynamicTablePage";

const PAGE_NAME = "Coupon Management";
const ITEM_NAME = "Coupon";
const ITEM_NAME_PLURAL = "Coupons";

const BASE_URL = `${EEndpointType.Admin}/coupons`;
const GET_ALL_URL = `${BASE_URL}/get-coupons`;
const GET_URL = `${BASE_URL}/get-coupon`;
const UPDATE_URL = `${BASE_URL}/update-coupon`;
const DELETE_URL = `${BASE_URL}/delete-coupon`;
const CREATE_URL = `${BASE_URL}/create-coupon`;

const baseQuery: IDynamicQueryWithRefresh = {
	refresh: Number.MIN_SAFE_INTEGER,
	joins: [
		{
			relation: "couponGroup",
		},
		{
			relation: "couponPrices",
		},
	],
};

const breadcrumbs: Breadcrumb[] = [
	{
		label: ITEM_NAME_PLURAL,
		url: CouponRoutes.BASE_ROUTE,
	},
];

const getPortalUrlEdit = (id?: number | string) =>
	id
		? CouponRoutes.EDIT_ROUTE.replace(":id", id.toString())
		: CouponRoutes.EDIT_ROUTE;

const create = async (
	coupon: ICreateCouponRequest,
): Promise<XCreateCouponResponse> => {
	return (await axios.post<ICoupon>(CREATE_URL, coupon)).data;
};

const update = async (coupon: IUpdateCouponRequest): Promise<ICoupon> => {
	return (await axios.post<ICoupon>(UPDATE_URL, coupon)).data;
};

const deleteItem = async (id: string): Promise<XDeleteCouponResponse> => {
	return (await axios.post(DELETE_URL, { id })).data;
};

const CouponsService = {
	ITEM_NAME,
	ITEM_NAME_PLURAL,
	PAGE_NAME,
	create,
	update,
	deleteItem,
	getPortalUrlEdit,
	baseQuery,
	breadcrumbs,
};

export default CouponsService;
