import { IRouter } from "src/types/router";

export const useFormLinks = (
	id: string,
	router: IRouter<boolean>,
) => {
	const copyUrl = router.COPY_ROUTE.replace(":id", id);

	const editUrl = router.EDIT_ROUTE.replace(":id", id);

	return {
		copyUrl,
		editUrl,
	};
};
