import React from "react";
import { Box, Link, makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

interface Props {
	title: string;
	to: string;
	openInNewTab?: boolean;
	wrapText?: boolean;
}
export const TitleLink = ({
	title,
	to,
	openInNewTab,
	wrapText = false,
}: Props) => {
	const classes = useStyles();
	const styleClass = wrapText ? classes.wrap : classes.noWrap;
	return (
		<Box display="flex">
			<div>
				<Link
					color="inherit"
					component={RouterLink}
					to={to}
					variant="h6"
					target={openInNewTab ? "_blank" : undefined}
				>
					<div className={styleClass}>{title}</div>
				</Link>
			</div>
		</Box>
	);
};

const useStyles = makeStyles(() => ({
	noWrap: {
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		maxWidth: 350,
	},

	wrap: {
		whiteSpace: "normal",
		overflow: "hidden",
		maxWidth: 350,
	},
}));
