import {
	useState,
	useEffect,
	useMemo,
	useRef,
	ReactNode,
	useContext,
	CSSProperties,
	useCallback,
} from "react";
import {
	Box,
	Container,
	Grid,
	IconButton,
	SvgIcon,
	CircularProgress,
	LinearProgress,
	Button,
	makeStyles,
	Theme,
} from "@material-ui/core";
import {
	DataGrid,
	GridRowId,
	DataGridProps,
	GridColDef,
	GridSelectionModel,
} from "@mui/x-data-grid";
import {
	fetchDataDynamic,
	useCountDataDynamic,
} from "src/hooks/useFetchDataDynamic";
import { TableChartTwoTone } from "@material-ui/icons";
import NProgress from "nprogress";
import { useNavigate } from "react-router-dom";
import { usePolling, IPollingOptions } from "src/hooks/usePolling";
import { DynamicTableUtility } from "src/utils/dynamicTable";
import { ScrollContext } from "src/contexts/ScrollContext";
import Header, { HeaderProps, Breadcrumb } from "src/components/Header";
import Page from "src/components/Page";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { Grid as GridIcon } from "react-feather";
import {
	XDynamicSqlData,
	XDynamicSqlName,
	IDynamicSqlQuery,
	XCondition,
	IOrderBy,
} from "amp";
import _ from "lodash";
import { renderConditionally } from "src/utils/render-conditionally";
import { useDataGridWidth } from "./hooks/useDataGridWidth";

const _COLUMNS = DynamicTableUtility.createColumns([
	{ name: "Index", field: "index", hide: true },
]);

const _DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

type XView = "datagrid" | "card";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDynamicQueryWithRefresh extends IDynamicSqlQuery {}

interface PropsRow<T extends XDynamicSqlName, K extends { id: GridRowId }>
	extends Props<T, K> {
	transformData: (item: XDynamicSqlData<T>) => K;
	transformDataGroup?: never;
}

interface PropsGroup<T extends XDynamicSqlName, K extends { id: GridRowId }>
	extends Props<T, K> {
	transformData?: never;
	transformDataGroup: (items: XDynamicSqlData<T>[]) => Promise<K[]>;
}

interface Props<T extends XDynamicSqlName, K extends { id: GridRowId }> {
	itemName: string;
	pageName: string;
	breadcrumbs?: Breadcrumb[];
	columns: GridColDef[];
	baseQuery: IDynamicQueryWithRefresh;
	debug?: boolean;
	tableName: T;
	customButtons?: JSX.Element[];
	rowHeight?: number;
	createRoute?: string;
	disableCreate?: boolean;
	bulkActionButtonLabel?: string;
	bulkAction?: (selectedItems: GridRowId[]) => Promise<void>;
	disableSelectionOnClick?: boolean;
	rowsPerPageOptions?: DataGridProps["rowsPerPageOptions"];
	children?: ReactNode;
	condition?: XCondition;
	orderBys?: IOrderBy[];
	hideHeader?: boolean;
	pollingConfiguration?: Omit<
		IPollingOptions<(K & { index: number })[]>,
		"initialValue"
	>;
	customRefreshQuery?: number;
	parentPageTitle?: string;
	usePaperTheming?: boolean;
	transformData?: (item: XDynamicSqlData<T>) => K;
	transformDataGroup?: (items: XDynamicSqlData<T>[]) => Promise<K[]>;
	cardRenderer?: (item: K & { index: number }) => ReactNode;
	style?: CSSProperties;
	onCountFetched?: (count: number) => void | Promise<void>;
	onDataFetched?: (items: XDynamicSqlData<T>[]) => void | Promise<void>;
	portalEntryPath?: HeaderProps["portalEntryPath"];
}

export type XDynamicTablePageProps<
	T extends XDynamicSqlName,
	K extends { id: GridRowId },
> = PropsRow<T, K> | PropsGroup<T, K>;

export function DynamicTablePage<
	T extends XDynamicSqlName,
	K extends { id: GridRowId },
>({
	itemName,
	pageName,
	breadcrumbs,
	columns: columnsProp,
	baseQuery,
	debug,
	tableName,
	customButtons,
	transformData,
	transformDataGroup,
	condition,
	orderBys,
	rowHeight = 36,
	createRoute,
	disableCreate,
	bulkActionButtonLabel,
	disableSelectionOnClick = false,
	bulkAction,
	pollingConfiguration,
	rowsPerPageOptions = _DEFAULT_ROWS_PER_PAGE_OPTIONS,
	cardRenderer,
	hideHeader,
	customRefreshQuery,
	parentPageTitle,
	usePaperTheming = false,
	onCountFetched,
	onDataFetched,
	portalEntryPath,
	style,
	children,
}: XDynamicTablePageProps<T, K>) {
	const classes = useStyles();
	const navigate = useNavigate();
	const columns = useMemo(
		() =>
			usePaperTheming
				? columnsProp.map((column) => ({
						...column,
						headerClassName:
							"MuiPaper-root" +
							(column.headerClassName ? ` ${column.headerClassName}` : ""),
				  }))
				: columnsProp,
		[usePaperTheming, columnsProp],
	);

	const isMountedRef = useIsMountedRef();
	const dataLoadingRef = useRef<Record<number, boolean | undefined>>({});
	const { isBottomScroll, setIsBottomScroll } = useContext(ScrollContext);
	const [refreshQuery, setRefreshQuery] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
	const [pageIndex, setPageIndex] = useState<number>(0);
	const [pollingTrigger, setPollingTrigger] = useState<number>(1);
	const [pageSize, setPageSize] = useState(rowsPerPageOptions[0]);
	const [data, setData] = useState<(K & { id: GridRowId; index: number })[]>();
	const [view, setView] = useState<XView>(cardRenderer ? "card" : "datagrid");
	const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
	const { value: pollingValue, setOptions } =
		usePolling<(K & { index: number })[]>(undefined);
	const { dataGridRef, dataGridWidth } = useDataGridWidth(!!bulkAction);

	const [query, setQuery] = useState({
		...baseQuery,
		refresh: (customRefreshQuery ?? 0) + refreshQuery,
		condition,
		orderBys: orderBys ?? baseQuery.orderBys,
		debug: debug ?? baseQuery.debug,
	});

	const {
		data: totalCount,
		loading: totalCountLoading,
		retry: refreshTotalCount,
	} = useCountDataDynamic(tableName, query);

	const pageData = useMemo(() => {
		const filteredData = data?.filter((pageDatum) => !!pageDatum);
		if (filteredData && totalCount !== null && view === "datagrid") {
			const pageIndexStart = pageIndex * pageSize;
			const pageIndexEnd = Math.min(totalCount, pageIndexStart + pageSize);
			return filteredData.slice(pageIndexStart, pageIndexEnd);
		}
		if (view === "datagrid") {
			return undefined;
		}
		return filteredData;
	}, [data, view, pageIndex, pageSize, totalCount]);

	const isAllDataLoaded = useMemo(
		() => pageData && pageData.length === totalCount,
		[pageData, totalCount],
	);

	const isDatagridDataReady = useMemo(
		() => totalCount !== null && pageData && pageData.length <= pageSize,
		[totalCount, pageData, pageSize],
	);

	const handleSelectionChange = useCallback((selected: GridSelectionModel) => {
		setSelectedRowIds(selected);
	}, []);

	const handleBulkAction = useCallback(async () => {
		await bulkAction?.(selectedRowIds);
	}, [bulkAction, selectedRowIds]);

	useEffect(() => {
		if (pollingConfiguration && data) {
			setOptions({
				...pollingConfiguration,
				initialValue: data,
			});
		}
	}, [pollingConfiguration, pollingTrigger]);

	const loadData = useCallback(
		async (pageIndexLoad: number, pageSizeLoad: number) => {
			setIsLoading(true);
			if (dataLoadingRef.current[pageIndexLoad]) {
				return;
			}
			dataLoadingRef.current[pageIndexLoad] = true;
			try {
				const offset = pageIndexLoad * pageSizeLoad;
				const limit = pageSizeLoad;
				const dataNew = await fetchDataDynamic(tableName, {
					...query,
					offset,
					limit,
				});
				await onDataFetched?.(dataNew);
				if (isMountedRef.current) {
					const dataTransformed = addIndexToData(
						transformDataGroup
							? await transformDataGroup(dataNew)
							: dataNew.map(transformData),
						offset,
					);
					if (isMountedRef.current) {
						setData((dataCurrent) => {
							return updateDataCurrent(
								dataCurrent,
								dataTransformed,
								offset,
								limit,
							);
						});
					}
				}
				dataLoadingRef.current[pageIndexLoad] = false;
				if (pollingConfiguration) {
					setPollingTrigger((current) => current + 1);
				}
			} catch (error) {
				dataLoadingRef.current[pageIndexLoad] = false;
				throw error;
			}
			setIsLoading(false);
		},
		[onDataFetched, transformDataGroup, transformData, query],
	);

	useEffect(() => {
		if (pollingValue) {
			setData(pollingValue);
		}
	}, [pollingValue]);

	useEffect(() => {
		if (totalCount != null) {
			onCountFetched?.(totalCount);
		}
	}, [totalCount]);

	useEffect(() => {
		refreshTotalCount();
	}, [refreshTotalCount]);

	const loadPageData = useMemo(
		() =>
			_.debounce(
				(pageIndexLoad: number, pageSizeLoad: number) => {
					loadData(pageIndexLoad, pageSizeLoad);
					if (dataLoadingRef.current[pageIndexLoad + 1] === undefined) {
						loadData(pageIndexLoad + 1, pageSizeLoad);
					}
				},
				500,
				{ leading: true, trailing: true },
			),
		[loadData],
	);

	useEffect(() => {
		setQuery((current) => ({
			...current,
			refresh: (customRefreshQuery ?? 0) + refreshQuery,
			condition,
			orderBys: orderBys ?? current.orderBys,
		}));
		dataLoadingRef.current = {};
		setData(undefined);
		setPageIndex(0);
		setSelectedRowIds([]);
	}, [refreshQuery, condition, orderBys, customRefreshQuery, pageSize]);

	useEffect(() => {
		loadPageData(pageIndex, pageSize);
	}, [query, pageIndex]);

	useEffect(() => {
		if (
			isBottomScroll &&
			!isLoading &&
			!isAllDataLoaded &&
			!totalCountLoading &&
			view === "card"
		) {
			setPageIndex((_pageIndex) => _pageIndex + 1);
			setIsBottomScroll(false);
		}
	}, [isBottomScroll, isLoading, isAllDataLoaded, totalCountLoading, view]);

	useEffect(() => {
		NProgress.start();

		return () => {
			NProgress.done();
		};
	}, []);

	return (
		<Page
			title={parentPageTitle || `${pageName} List`}
			className={usePaperTheming ? "MuiPaper-root" : undefined}
		>
			<Container
				maxWidth={false}
				style={{ width: "100%", height: "100%", ...style }}
				className={usePaperTheming ? "MuiPaper-root" : undefined}
			>
				{!hideHeader && (
					<Header
						create={createRoute ? () => navigate(createRoute) : undefined}
						itemName={itemName}
						pageName={pageName}
						refresh={() =>
							setRefreshQuery((refreshQueryCurrent) => refreshQueryCurrent + 1)
						}
						breadcrumbs={breadcrumbs}
						customButtons={customButtons}
						disableCreate={disableCreate}
						portalEntryPath={portalEntryPath}
					/>
				)}
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					spacing={3}
				>
					{children}
					{cardRenderer && (
						<Grid item>
							<IconButton
								style={{ padding: 4 }}
								disabled={view === "card"}
								onClick={() => setView("card")}
								title="Tile View"
							>
								<SvgIcon fontSize="medium">
									<GridIcon />
								</SvgIcon>
							</IconButton>
							<IconButton
								style={{ padding: 4 }}
								disabled={view === "datagrid"}
								onClick={() => {
									setPageIndex(0);
									setView("datagrid");
								}}
								title="Table View"
							>
								<SvgIcon fontSize="medium">
									<TableChartTwoTone />
								</SvgIcon>
							</IconButton>
						</Grid>
					)}
				</Grid>
				<Box mt={3} width="100%" height="100%">
					{renderConditionally(
						bulkAction && selectedRowIds.length > 0,
						<div
							className={classes.bulkActions} 
							style={{ width: dataGridWidth - _BULK_ACTION_CHECKBOX_WIDTH }}
						>
							<Button
								className={classes.bulkAction}
								variant="contained"
								color="primary"
								onClick={handleBulkAction}
								disabled={selectedRowIds.length === 0}
							>
								{bulkActionButtonLabel || "Bulk Action"}
							</Button>
						</div>
					)}
					{view === "datagrid" && !isDatagridDataReady && (
						<div
							style={{
								paddingTop: 150,
								width: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<LinearProgress style={{ width: 400 }} />
						</div>
					)}
					{view === "datagrid" && isDatagridDataReady && (
						<DataGrid
							className={usePaperTheming ? "MuiPaper-root" : undefined}
							autoHeight
							rowHeight={rowHeight}
							columns={[...columns, ..._COLUMNS]}
							rows={pageData ?? []}
							sortModel={[{ field: "index", sort: "asc" }]}
							disableColumnMenu
							pagination
							page={pageIndex}
							pageSize={pageSize}
							onPageChange={setPageIndex}
							paginationMode="server"
							rowCount={totalCount ?? undefined}
							rowsPerPageOptions={rowsPerPageOptions ?? _DEFAULT_ROWS_PER_PAGE_OPTIONS}
							onPageSizeChange={setPageSize}
							checkboxSelection={!!bulkAction}
							onSelectionModelChange={handleSelectionChange}
							ref={dataGridRef}
							disableSelectionOnClick={disableSelectionOnClick}
						/>
					)}
					{view === "card" && (
						<>
							<Grid
								container
								direction="row"
								spacing={3}
								wrap="wrap"
								justifyContent="center"
								alignItems="center"
							>
								{cardRenderer &&
									pageData?.map((item) => (
										<Grid key={item.id} item style={{ alignSelf: "stretch" }}>
											{cardRenderer(item)}
										</Grid>
									))}
							</Grid>
							{!isAllDataLoaded && !isLoading && (
								<Box
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										width: "100%",
										height: 100,
									}}
								>
									<CircularProgress />
								</Box>
							)}
						</>
					)}
				</Box>
			</Container>
		</Page>
	);
}

const addIndexToData = <K extends { id: GridRowId }>(
	data: K[],
	offset: number,
) => {
	return data.map((datumTransformed, index) => ({
		...datumTransformed,
		id: datumTransformed?.id ?? index + offset,
		index: index + offset,
	}));
};

const updateDataCurrent = <K extends { id: GridRowId; index: number }>(
	dataCurrent: K[] | undefined,
	dataTransformed: K[],
	offset: number,
	limit: number,
): (K & { id: GridRowId; index: number })[] => {
	const dataCount = offset + limit;
	const dataUpdated: (K | undefined)[] = dataCurrent ? [...dataCurrent] : [];
	while (dataUpdated.length < dataCount) {
		dataUpdated.push(undefined);
	}
	dataUpdated.splice(offset, dataTransformed.length, ...dataTransformed);
	return dataUpdated as (K & { id: GridRowId; index: number })[];
};

const _BULK_ACTION_CHECKBOX_WIDTH = 51;
const useStyles = makeStyles((theme: Theme) => ({
	bulkActions: {
		marginLeft: _BULK_ACTION_CHECKBOX_WIDTH,
		marginTop: 10,
		position: "absolute",
		zIndex: 11,
		backgroundColor: theme.palette.background.default
	},
	bulkAction: {
		marginLeft: theme.spacing(2)
	},
}));