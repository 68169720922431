import { EEndpointType, ICouponCountsByCouponGroupResponse, ICouponGroup, XGeneralMultiTenantWritable, XGeneralMultiTenantWritableWithId, ICreateOrUpdateCouponGroupResponse } from "amp";
import { IDynamicQueryWithRefresh } from "src/components/dynamic-table/DynamicTablePage";
import axios from "src/utils/axios";
import { CouponGroupRoutes } from "./coupon-group-routes";

const ITEM_NAME = "Coupon Group";
const ITEM_NAME_PLURAL = "Coupon Groups";

const BASE_URL = `${EEndpointType.Admin}/coupon-groups`;

const UPDATE_URL = `${BASE_URL}/update`;
const CREATE_URL = `${BASE_URL}/create`;

const baseQuery: IDynamicQueryWithRefresh = {
	refresh: Number.MIN_SAFE_INTEGER,
};

const getCouponCountsByCouponGroup = async (couponGroupIds: number[]): Promise<ICouponCountsByCouponGroupResponse> => {
	return (await axios.post<ICouponCountsByCouponGroupResponse>(`${BASE_URL}/get-coupon-counts`, { couponGroupIds })).data;
};

const getPortalUrlEdit = (id?: number | string) =>
	id
		? CouponGroupRoutes.EDIT_ROUTE.replace(":id", id.toString())
		: CouponGroupRoutes.EDIT_ROUTE;

const createCouponGroup = async (couponGroup: XGeneralMultiTenantWritable<ICouponGroup>) => {
	return axios.post<ICreateOrUpdateCouponGroupResponse>(CREATE_URL, couponGroup);
};

const editCouponGroup = async (couponGroup: XGeneralMultiTenantWritableWithId<ICouponGroup>) => {
	return axios.post<ICreateOrUpdateCouponGroupResponse>(UPDATE_URL, couponGroup);
};

export const CouponGroupService = {
	ITEM_NAME,
	ITEM_NAME_PLURAL,
	getCouponCountsByCouponGroup,
	UPDATE_URL,
	CREATE_URL,
	createCouponGroup,
	editCouponGroup,
	baseQuery,
	getPortalUrlEdit,
};
