import { useEffect, useRef, useState } from "react";

export interface IPollingOptions<TValue> {
	fetchValue: (initialValues: TValue) => Promise<TValue>;
	stopCondition: (data: TValue | undefined) => boolean;
	interval: number;
	timeout?: number;
	initialValue?: TValue;
	noInitialDelay?: boolean;
}

interface IPolling<TValue> {
	complete: boolean;
	value: TValue | undefined;
	setOptions: (options: IPollingOptions<TValue>) => void
}

export function usePolling<TValue>(
	pollingOptions: IPollingOptions<TValue> | undefined,
): IPolling<TValue> {
	// Set current fetch id
	const currentFetchId = useRef(0);
	// Set polling options
	const [currentPollingOptions, setOptions] =
		useState<IPollingOptions<TValue> | undefined>(pollingOptions);
	const {
		fetchValue,
		interval,
		stopCondition,
		timeout,
		initialValue,
		noInitialDelay,
	} = currentPollingOptions ?? {};
	// Get value
	const [value, setValue] = useState<TValue | undefined>(initialValue);
	// Get complete
	const [complete, setComplete] = useState<boolean>(false);

	async function getValueAndSet(fetchId: number): Promise<TValue | undefined> {
		try {
			// Fetch value
			const currentValue = initialValue && await fetchValue?.(initialValue);
			// Check if current fetch id
			if (fetchId === currentFetchId.current) {
				// Set value
				setValue(currentValue);
			}
			// return value
			return currentValue;
		} catch (e) {
			// eslint-disable-next-line no-console
			console.warn(e);
			return undefined;
		}
	}

	function maybeCompleteInterval(
		timerId: NodeJS.Timeout,
		currentData: TValue | undefined,
	): void {
		// Get should stop
		const shouldStop = checkStopCondition(currentData);
		// Check if should stop
		if (shouldStop) {
			// Clear interval
			clearInterval(timerId);
		}
	}

	function checkStopCondition(currentData: TValue | undefined): boolean {
		// Get should stop
		const shouldStop = stopCondition?.(currentData);
		// Check if should stop and if interval is not complete
		if (shouldStop && !complete && currentFetchId.current > -1) {
			// Set complete
			setComplete(true);
		}
		// Return should stop
		return !!shouldStop;
	}

	useEffect(() => {
		// Check if polling options exists
		if (!currentPollingOptions || !initialValue) {
			// Do nothing
			return;
		}
		// Get condition met from initial value
		const conditionMet = checkStopCondition(initialValue);
		// Check if initial value meet stop condition
		if (!conditionMet) {
			// set interval action
			const intervalAction = async () => {
				// Increment fetch id
				currentFetchId.current += 1;
				// Get current value
				const currentValue = await getValueAndSet(currentFetchId.current);
				// Complete interval if condition met
				maybeCompleteInterval(timer, currentValue);
			};
			// Check if no initial delay
			if (noInitialDelay) {
				// Execute interval action
				intervalAction();
			}
			// Set timer
			const timer = setInterval(intervalAction, interval);
			// Set timeout condition
			const setTimeoutTimer = setTimeout(() => {
				// Check if not complete
				if (!complete) {
					// Clear interval if not complete
					clearInterval(timer);
				}
			}, timeout);

			return () => {
				// Clear fetch id to prevent setting state
				currentFetchId.current = -1;
				if (timer) {
					// Clear interval on unmount
					clearInterval(timer);
				}
				if (setTimeoutTimer) {
					// Clear set time out
					clearTimeout(setTimeoutTimer);
				}
			};
		}
		// Do nothing on unmount
		return () => undefined;
	}, [currentPollingOptions]);
	// Return complete and value
	return {
		complete, 
		value, 
		setOptions
	};
}
