import { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { CSVLink } from "react-csv";
import { countDataDynamic, fetchDataDynamic } from "src/hooks/useFetchDataDynamic";
import { IDynamicQueryWithRefresh } from "./dynamic-table/DynamicTablePage";
import { Button, CircularProgress, IconButton, SvgIcon } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import { formatDateMMDDYYYY } from "src/utils/formatDate";
import { XMaybe, XDynamicSqlName, XDynamicSqlData } from "amp";

export interface ExportToCSVProps<T extends object, K extends XDynamicSqlName> {
	query: IDynamicQueryWithRefresh;
	tableName: K;
	transformData: (items: XDynamicSqlData<K>[]) => Promise<T[]> | T[];
	fileName: string;
	snackBarLabel: string;
	pageSize?: number;
	type?: "Button" | "Action_Item";
	color?: "primary" | "secondary" | "default";
}

export function ExportToCSVButton<T extends object, K extends XDynamicSqlName>(
	{ 
		tableName, 
		query, 
		fileName, 
		transformData, 
		snackBarLabel, 
		pageSize,
		type = "Button",
		color = "secondary",
}: ExportToCSVProps<T, K>,
) {
	const { enqueueSnackbar } = useSnackbar();
	const [dataToExport, setDataToExport] = useState<T[]>();
	const [headers, setHeaders] = useState<unknown[]>();
	const downloadRef = useRef<CSVLink>();
	const [exportCompleteness, setExportCompleteness] = useState(-1);
	
	async function createCSV() {
		try {
			setExportCompleteness(0);
			const transformedData = await _exportData(
				tableName,
				transformData,
				query,
				_getPageSize(pageSize),
				setExportCompleteness,
			);
			setDataToExport(transformedData);
			setHeaders(_getHeaders(transformedData));
		} catch {
			enqueueSnackbar(`${snackBarLabel} Export Failed`, { variant: "error" });
		}
		setExportCompleteness(-1);
	}

	useEffect(() => {
		if (headers) {
			downloadRef.current.link.click();
			enqueueSnackbar(`${snackBarLabel} Exported`, { variant: "success" });
		}
	}, [headers]);

	return (
		<>
			{type === "Button" ? (
    			<Button
					startIcon={<GetAppIcon />}
					variant="contained"
					color={color}
					onClick={createCSV}
					disabled={exportCompleteness !== -1}
				>
					{exportCompleteness !== -1
						? `Exporting to CSV (${_displayCompleteness(exportCompleteness)})`
						: "Export to CSV"}
				</Button>
  			) : (
     			<IconButton
					onClick={createCSV}
					component="button"
					disabled={exportCompleteness !== -1}
				>
					{exportCompleteness !== 0 ? (
						<SvgIcon fontSize="small">
							<GetAppIcon />
						</SvgIcon>
    				)
						: <CircularProgress size={16} />
					}	
				</IconButton>
   			)}
			<CSVLink
				data={dataToExport ?? ""}
				headers={headers}
				filename={`${fileName.toLocaleLowerCase().replace(" ", "")}_${formatDateMMDDYYYY(Date(), ".")}.csv`}
				data-interception="off"
				ref={downloadRef}
				target="_blank"
				style={{ textDecoration: 'none' }}
			 />
		</>
	);
}

async function _exportData<T extends object, K extends XDynamicSqlName>(
	tableName: K,
	transformData: (items: XDynamicSqlData<K>[]) => Promise<T[]> | T[],
	query: IDynamicQueryWithRefresh,
	pageSize: number,
	setCompleteness: (completeness: number) => void,
): Promise<T[]> {
	const transformedData: T[] = [];
	const transformedDataCount = await countDataDynamic(tableName, { ...query });
	let pageIndex = 0;
	while (true) {
		const data = await fetchDataDynamic(tableName, {
			...query,
			offset: pageIndex * pageSize,
			limit: pageSize,
		});
		if (!data.length) {
			return transformedData;
		}
		transformedData.push(...await transformData(data));
		setCompleteness(transformedData.length / transformedDataCount);
		pageIndex++;
	}
}

function _getPageSize(pageSize: XMaybe<number>): number {
	return Math.max(1, Math.min(1000, pageSize ?? 100));
}

function _getHeaders(data: unknown[]): unknown[] {
	return Object.keys(data[0] ?? {})?.filter((key) => key !== "id")?.map((key) => ({ label: key, key })) ?? [];
}

function _displayCompleteness(completeness: number): string {
	return completeness === -1 ? "" : `${Math.max(1, Math.round(completeness * 100))}%`;
}
