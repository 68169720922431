import { Box, Container, makeStyles } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import Header, { Breadcrumb } from "src/components/Header";
import Page from "src/components/Page";
import SimpleTabs, { TabItem } from "src/components/SimpleTabs";
import { RefreshQueryProvider, useRefreshQueryContext } from "src/contexts/RefreshQueryContext";
import { withProviders } from "src/enhancers/withProviders";
import { useFetchData } from "src/hooks/useFetchData";
import { Theme } from "src/theme";
import CouponsService from "../coupons/coupons.service";
import CouponsList from "../coupons/CouponsList";
import { PromoCodeRoutes } from "../promo-codes/promo-code.routes";
import PromoCodeService from "../promo-codes/promoCode.service";
import PromoCodesList from "../promo-codes/PromoCodeList";
import { CouponGroupList } from "./coupon-groups/coupon-group-list-view/CouponGroupList";
import { CouponGroupRoutes } from "./coupon-groups/coupon-group-routes";
import { CouponGroupService } from "./coupon-groups/coupon-group.service";
import { CouponRoutes } from "./coupons.routes";

export enum ECouponManagementTabKey {
	Coupons = "COUPONS",
	PromoCodes = "PROMO_CODES",
	CouponGroups = "COUPON_GROUPS",
}

const tabs: TabItem[] = [
	{ key: ECouponManagementTabKey.Coupons, label: "Coupons" },
	{ key: ECouponManagementTabKey.PromoCodes, label: "Promotion Codes" },
	{ key: ECouponManagementTabKey.CouponGroups, label: "Coupon Groups" },
];

const breadcrumbs: Breadcrumb[] = [
	{
		label: CouponsService.ITEM_NAME_PLURAL,
		url: CouponRoutes.LIST_ROUTE,
	},
];

const CouponGenerateView = withProviders(RefreshQueryProvider)(() => {
	const { refresh } = useRefreshQueryContext();
	const [currentTab, setCurrentTab] = useState<ECouponManagementTabKey>();
	const {
		data: promoCodes,
		loading: promoCodesLoading,
		retry: fetchPromoCodes,
	} = useFetchData(PromoCodeService.getAll);

	const classes = useStyles();
	const navigate = useNavigate();

	const goToCreateCoupon = useCallback(() => {
		switch (currentTab) {
			case ECouponManagementTabKey.Coupons:
				navigate(CouponRoutes.CREATE_ROUTE);
				break;
			case ECouponManagementTabKey.PromoCodes:
				navigate(PromoCodeRoutes.CREATE_ROUTE);
				break;
			case ECouponManagementTabKey.CouponGroups:
				navigate(CouponGroupRoutes.CREATE_ROUTE);
				break;
			default:
				throw new Error(`Coupon tab key not handled: (${currentTab})`);
		}
	}, [navigate, currentTab]);

	const getTabPage = useCallback((key: ECouponManagementTabKey) => {
		if (currentTab !== key) {
			setCurrentTab(key);
		}
		switch (key) {
			case ECouponManagementTabKey.Coupons:
				return <CouponsList />;
			case ECouponManagementTabKey.PromoCodes:
				return 	(
					<Box mt={3}>
						<PromoCodesList
							promoCodes={promoCodes}
							loading={promoCodesLoading}
						/>
			</Box>
	);
			case ECouponManagementTabKey.CouponGroups:
				return <CouponGroupList />;
			default:
				throw new Error(`Contacts tab key not handled: (${key})`);
		}
	}, [currentTab, promoCodesLoading, promoCodes]);

	return (
		<Page title={CouponsService.ITEM_NAME} className={classes.root}>
			<Container maxWidth={false}>
				<Header
					itemName={getItemName(currentTab)}
					pageName={CouponsService.PAGE_NAME}
					breadcrumbs={breadcrumbs}
					refresh={refresh}
					create={goToCreateCoupon}
				/>
				<SimpleTabs tabItems={tabs}>
					{(selectedTab, _index) => {
						return getTabPage(selectedTab.key as ECouponManagementTabKey);
					}}
				</SimpleTabs>
			</Container>
		</Page>
	);
});

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
}));

export default CouponGenerateView;

function getItemName(currentTab: ECouponManagementTabKey) {
	if (currentTab === ECouponManagementTabKey.Coupons) {
		return CouponsService.ITEM_NAME;
	} if (currentTab === ECouponManagementTabKey.PromoCodes) {
		return PromoCodeService.ITEM_NAME;
	} if (currentTab === ECouponManagementTabKey.CouponGroups) {
		return CouponGroupService.ITEM_NAME;
	}
	return "";
}