import { XOptional } from "amp";
import { format, isToday, isYesterday } from "date-fns";

type DateValue = string | Date | number;

export const formatDate = (date: XOptional<Date | string>): string => {
  if (!date) {
    return "";
  }
  return new Date(date).toLocaleString("en-US");
};

export const formatDateTime = (date: Date | string): string => {
  const dateObject = new Date(date);
  let dateFormat = "MM/dd/yyyy";

  if (isToday(dateObject)) {
    dateFormat = "'Today'";
  } else if (isYesterday(dateObject)) {
    dateFormat = "'Yesterday'";
  }

  return format(dateObject, `${dateFormat} @ h:mma`);
};

export const formatDateMMDDYYYY = (
  date: Date | string,
  joinChar = "/",
): string => {
  const _date = new Date(date);
  return [
    _date.getMonth() + 1,
    _date.getDate(),
    _date.getFullYear(),
  ].join(joinChar);
};

export const formatDateFullDateMMSS = (
  date: Date | string,
  joinChar = "/",
): string => {
  const _date = new Date(date);
  const fullDate = [
    _date.getMonth() + 1,
    _date.getDate(),
    _date.getFullYear(),
  ].join(joinChar);
  const time = formatAMPM(_date);
  return `${fullDate} ${time}`;
};

export const toDisplayDate = (dateValue: DateValue): string =>
  toDate(dateValue).toLocaleDateString("en-US");

const formatAMPM = (date: Date): string => {
  let hours = date.getHours();
  const ampm = hours && hours < 12 ? "AM" : "PM";
  hours = hours % 12;
  hours = hours || 12; 
  const minutes = date.getMinutes();
  const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutesFormatted}  ${ampm}`;
};

const toDate = (value: DateValue): Date =>
  (value instanceof Date ? value : new Date(value));
