import {
	TableRow,
	TableCell,
	IconButton,
	Link,
	SvgIcon,
} from "@material-ui/core";
import PromoCodeService from "./promoCode.service";
import { IPromotionCode } from "amp";
import Results from "src/components/Results";
import { Link as RouterLink } from "react-router-dom";
import { Edit } from "react-feather";

interface IProps {
	promoCodes: IPromotionCode[];
	loading: boolean;
}

const RenderTableHead = () => {
	return (
		<TableRow>
			<TableCell>Promotion Code</TableCell>
			<TableCell>Label Override</TableCell>
			<TableCell>Redemptions</TableCell>
			<TableCell>Expires</TableCell>
			<TableCell>Created On</TableCell>
			<TableCell>Actions</TableCell>
		</TableRow>
	);
};

const PromoCodesList = ({ loading, promoCodes }: IProps) => {
	return (
		<Results<IPromotionCode>
			loading={loading}
			itemName={PromoCodeService.ITEM_NAME}
			itemNamePlural={PromoCodeService.ITEM_NAME_PLURAL}
			renderTableHead={RenderTableHead}
			items={promoCodes ?? []}
			renderTableRow={(_results, item) => {
				return (
					<TableRow hover key={item.id}>
						<TableCell>
							<Link
								color="inherit"
								component={RouterLink}
								to={PromoCodeService.getPortalUrlEdit(item.id)}
								variant="h6"
							>
								{item.code}
							</Link>
						</TableCell>
						<TableCell>{item.coupon?.label_override}</TableCell>
						<TableCell>{item.max_redemptions}</TableCell>
						<TableCell>{item.expires_at}</TableCell>
						<TableCell>{item.created}</TableCell>
						<TableCell>
							<IconButton
								component={RouterLink}
								to={PromoCodeService.getPortalUrlEdit(item.id)}
							>
								<SvgIcon fontSize="small">
									<Edit />
								</SvgIcon>
							</IconButton>
						</TableCell>
					</TableRow>
				);
			}}
		/>
	);
};

export default PromoCodesList;
