import { EEndpointType,
	IAddUserData,
	IPaymentInformationResponse,
	IAccountContext,
	IBillingAccount,
	StripeCardInfo,
	IRemoveUsers,
	IModifyVehicleSubscription,
	IModifyUserSubscription,
	IAccountModificationRequest,
	IAccountInvoice,
	IAddVehicles,
	IModifyVehicle,
	IEvoxVehicleCustomData,
	ICreateCustomVehicleRequest,
	StripeBankInfo,
} from "amp";
import axios from "src/utils/axios";
import { CancelFormSubmit } from "./components/CancelSubscription/CancelSubscriptionModal";
import { PauseFormSubmit } from "./components/PauseSubscription/forms/PauseForm";
import { UnpauseFormSubmit } from "./components/PauseSubscription/forms/UnpauseForm";

export const BILLING_ACCOUNT_ENDPOINT = `${EEndpointType.User}/account-billing`;
const API_URL_PAYMENT_USER = `${EEndpointType.User}/payment/`;
const API_URL_ACCOUNTS = `/${EEndpointType.User}/accounts/`;
const API_UPDATE_PAYMENT_MENTHOD_URL = API_URL_PAYMENT_USER + "update-payment-method";
const API_ADD_USERS_URL = API_URL_ACCOUNTS + "add-users";
const _ACCOUNTS_END_POINT = `${EEndpointType.User}/accounts`;
const API_ADD_VEHICLE = `${_ACCOUNTS_END_POINT}/add-vehicles`;
const API_EDIT_VEHICLE = `${_ACCOUNTS_END_POINT}/modify-vehicle`;
const API_CREATE_UPDATE_CUSTOM_VEHICLE = `${EEndpointType.User}/evox/create-custom-vehicle`;

const getBillingAccounts = async (accountIds: string[]): Promise<IBillingAccount[]> => {
	return (await (
		axios.post<IBillingAccount[]>(
			`${BILLING_ACCOUNT_ENDPOINT}/billing-accounts`, { accountIds }
		))
	).data;
};

async function addVehicle(vehicleInfo: IAccountModificationRequest<IAddVehicles>): Promise<IAccountContext> {
	return (await axios.post<IAccountContext>(API_ADD_VEHICLE, vehicleInfo)).data;
}

async function editVehicle(vehicleInfo: IAccountModificationRequest<IModifyVehicle>): Promise<IAccountContext> {
	return (await axios.post<IAccountContext>(API_EDIT_VEHICLE, vehicleInfo)).data;
}

async function createOrUpdateVehicleCustom(values: ICreateCustomVehicleRequest): Promise<IEvoxVehicleCustomData> {
	return (await axios.post<IEvoxVehicleCustomData>(API_CREATE_UPDATE_CUSTOM_VEHICLE, values)).data;
}

const getAccountContext = async (accountId: string): Promise<IAccountContext> => {
	return (await (axios.post<IAccountContext>(`${_ACCOUNTS_END_POINT}/get-account`, { accountId }))).data;
};

const getUpComingInvoices = async (accountIds: string[]): Promise<IAccountInvoice[]> => {
 	return (await (
		axios.post<IAccountInvoice[]>(
			`${BILLING_ACCOUNT_ENDPOINT}/upcoming-account-invoices`, { accountIds }
		))
	).data;
};

async function updatePaymentMethod(
	paymentTokenId: string | undefined,
	paymentMethodId: string | undefined,
	customerId: string | undefined,
	updateSubscriptions?: boolean,
): Promise<StripeCardInfo | StripeBankInfo | null> {
	const paymentResponse = await axios.post<IPaymentInformationResponse>(API_UPDATE_PAYMENT_MENTHOD_URL, {
		paymentTokenId,
		paymentMethodId,
		customerId,
    	updateSubscriptions,
	});
	return paymentResponse.data.paymentMethod?.card
		|| paymentResponse.data.paymentMethod?.bank
		|| null;
}

const removeUsersFromAccount = async (
	accountId: string,
	values?: IRemoveUsers
): Promise<IAccountContext> => {
	const mappedValues = 
	{	accountId,
			modification: {
				userExternalIds: values?.userExternalIds,
				comment: values?.comment,
		},
	};
	return (await axios.post(
		`${API_URL_ACCOUNTS}remove-users`,
		mappedValues
	)).data;
};

const addUsersToAccount = async (
	users: IAddUserData[],
	accountId: string,
): Promise<IAccountContext> => {
	const context = (await axios.post<IAccountContext>(API_ADD_USERS_URL, {
		modification: { users },
		accountId,
	})).data;
	return context;
};

const removeVehicleSubscriptions = async (vehicleIds: number[], values: CancelFormSubmit) => {
	const mappedValues = 
	{	accountId: values.accountId,
			modification: {
				vehicleIds,
				cancellationReasonId: values.cancellationReasonId,
				comment: values.comment,
				cancelAtPeriodEnd: values.cancelAtPeriodEnd,
		},
	};
	return axios.post(
		`${EEndpointType.User}/accounts/cancel-subscriptions`,
		mappedValues
	);
};

const modifyVehicleSubscription = async (
	accountId: string,
	vehicleId: number,
	priceId: number,
) => {
	return axios.post(
		`${EEndpointType.User}/accounts/modify-vehicle-subscription`,
		{
			accountId,
			modification: {
				vehicleId,
				priceId,
			},
		} as IAccountModificationRequest<IModifyVehicleSubscription>
	);
};

const modifyUserSubscription = async (accountId: string, priceId: number) => {
	return axios.post(
		`${EEndpointType.User}/accounts/modify-user-subscription`,
		{
			accountId,
			modification: {
				priceId,
			},
		} as IAccountModificationRequest<IModifyUserSubscription>,
	);
};

const reactivateVehicleSubscriptions = async (vehicleIds: number[], accountId: string) => {
	const mappedValues = 
	{	
		accountId,
			modification: {
				vehicleIds,
			},
	};
	return axios.post(
		`${EEndpointType.User}/accounts/reactivate-vehicle-subscriptions`,
		mappedValues
	);
};

const reactivateVehicleSingleSubscription = async (accountId: string) => {
	const { data } = await axios.post<IAccountContext>(
		`${EEndpointType.User}/accounts/reactivate-vehicle-single-subscriptions`,
		{	
			accountId,
			modification: {},
		}
	);
	return data;
};

const reactivateUserSubscription = async (accountId: string) => {
	return axios.post(
		`${EEndpointType.User}/accounts/reactivate-user-subscription`,
		{ accountId, modification: {} },
	);
};

const triggerVehicles = async (vehicleIds: number[], accountId: string) => {
	return axios.post(
		`${EEndpointType.User}/accounts/trigger-vehicles`,
		{ accountId, modification: { vehicleIds } },
	);
};

const removeVehicles = async (vehicleIds: number[], accountId: string): Promise<IAccountContext> => {
	return (await axios.post(
		`${EEndpointType.User}/accounts/remove-vehicles`,
		{ accountId, modification: { vehicleIds } },
	)).data;
};

const cancelSubscription = async (values: CancelFormSubmit): Promise<void> => {
	const mappedValues = 
	{		accountId: values.accountId,
			modification: {
			cancelAtPeriodEnd: values.cancelAtPeriodEnd,
			cancellationReasonId: values.cancellationReasonId,
			comment: values.comment,
		},
	};
	await axios.post(
		`${EEndpointType.User}/accounts/cancel-subscriptions`,
		mappedValues
	);
};

const pauseUserSubscription = async (values: PauseFormSubmit): Promise<void> => {
	return axios.post(
		`${EEndpointType.User}/accounts/pause-user-subscription`,
		{
			accountId: values.accountId,
			modification: {
				numberOfCycles: values.numberOfCycles,
				comment: values.comment,
			},
		},
	);
};

const unpauseUserSubscription = async (values: UnpauseFormSubmit): Promise<void> => {
	return axios.post(
		`${EEndpointType.User}/accounts/unpause-user-subscription`,
		{
			accountId: values.accountId,
			modification: {
				comment: values.comment,
			},
		},
	);
};

const pauseVehicleSubscription = async (values: PauseFormSubmit): Promise<void> => {
	return axios.post(
		`${EEndpointType.User}/accounts/pause-vehicle-subscription`,
		{
			accountId: values.accountId,
			modification: {
				vehicleId: values.vehicleId,
				numberOfCycles: values.numberOfCycles, 
				comment: values.comment,
			},
		},
	);
};

const unpauseVehicleSubscription = async (values: UnpauseFormSubmit): Promise<void> => {
	return axios.post(
		`${EEndpointType.User}/accounts/unpause-vehicle-subscription`,
		{
			accountId: values.accountId,
			modification: {
				vehicleId: values.vehicleId,
				comment: values.comment,
			},
		},
	);
};

export const AccountService = {
	getBillingAccounts,
	getAccountContext,
	addVehicle,
	editVehicle,
	removeVehicles,
	getUpComingInvoices,
	removeUsersFromAccount,
	removeVehicleSubscriptions,
	modifyVehicleSubscription,
	modifyUserSubscription,
	reactivateVehicleSubscriptions,
	reactivateVehicleSingleSubscription,
	reactivateUserSubscription,
	triggerVehicles,
	cancelSubscription,
	addUsersToAccount,
	updatePaymentMethod,
	pauseUserSubscription,
	pauseVehicleSubscription,
	unpauseUserSubscription,
	unpauseVehicleSubscription,
	createOrUpdateVehicleCustom,
};
