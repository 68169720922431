import { ReactNode, useCallback, useEffect, useState } from 'react';
import type { ChangeEvent } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import _, { get } from "lodash";
import {
	Box,
	Button,
	Card,
	Checkbox,
	InputAdornment,
	SvgIcon,
	TablePagination,
	TextField,
	makeStyles,
	FormControlLabel,
	TableHead,
	Table,
	TableBody,
	FormHelperText,
	Grid,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
} from '@material-ui/core';
import {
	Search as SearchIcon
} from 'react-feather';
import type { Theme } from 'src/theme';
import { PORTAL_TABLE_STATE, THEMES } from 'src/constants';
import LoadingScreen from './LoadingScreen';
import { DragDropContext, Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import usePersistedState from 'src/hooks/usePersistedState';
import { MultiselectWithAll } from './MultiselectWithAll';

export interface ResultsObject<T> {
	selectedAllItems: boolean;
	handleSelectAllItems: (event: ChangeEvent<HTMLInputElement>) => void;
	selectedSomeItems: boolean;
	handleSelectOneItem: (event: ChangeEvent<HTMLInputElement>, itemId: number | string) => void;
	paginatedItems: T[];
	selectedItems: (number | string)[];
	deletedClass: string;
}

export type SortDirection = "asc" | "desc";

export interface SortOption<T> {
	key: keyof T | string[];
	label: string;
	direction: SortDirection;
	reorder?: boolean;
}

interface SortOptionsOperator<T> {
	(sortOptions: SortOption<T>[]): SortOption<T>[]
}

interface IResultsFilterOption {
	value: string | number;
	label: string;
}

interface IDropdownFilterConfig<T> {
	key: keyof T;
	options: IResultsFilterOption[];
}

interface IMulitSelectFilterConfig<T> {
	getComparisonValue: (item: T) => string | number | (string | number)[];
	options: IResultsFilterOption[];
}

export function buildSortOptions<T>(
	...operators: SortOptionsOperator<T>[]
): SortOption<T>[] {
	return operators.reduce((accum, current) => current(accum), [] as SortOption<T>[]);
}

export function withKey<T>(
	key: keyof T,
	label: string,
	suffixes: { asc: string, desc: string }
): SortOptionsOperator<T> {
	return (sortOptions) => sortOptions.concat({
		key,
		direction: "asc",
		label: `${label} ${suffixes.asc}`,
	}, {
		key,
		direction: "desc",
		label: `${label} ${suffixes.desc}`,
	});
}

export function withReorder<T>(): SortOptionsOperator<T> {
	return (sortOptions) => {
		if (sortOptions.length < 2) {
			console.warn("`withReorder` cannot be invoked before adding a key");
			return sortOptions;
		}

		return sortOptions
			.slice(0, sortOptions.length - 2)
			.concat({
				...sortOptions[sortOptions.length - 2],
				reorder: true,
			})
			.concat(sortOptions.slice(sortOptions.length - 1));
	};
}

interface ResultsProps<T> {
	className?: string;
	items: T[];
	loading?: boolean;
	itemName: string;
	itemNamePlural: string;
	filterProperties?: ((keyof T) | string[])[];
	customSearchFilter?: ((item: T, query: string) => boolean);
	groupFilter?: keyof T | ((item: T) => boolean);
	dropdownFilter?: IDropdownFilterConfig<T>;
	dropdownFilterLabel?: string;
	multiselectFilter?: IMulitSelectFilterConfig<T>;
	multiselectFilterLabel?: string;
	filteredLabel?: string;
	selectedKey?: string;
	isItemSelectable?: (item: T) => boolean;
	selectedItemsAction?: (ids: (number | string)[]) => Promise<unknown>;
	selectedItemsActionLabel?: string;
	disableSelectedItemsActions?: boolean;
	disableSearch?: boolean;
	disableSort?: boolean;
	disablePersistedState?: boolean;
	sortOptions?: SortOption<T>[];
	onReorder?: (reorderedItems: T[]) => Promise<void> | void | null;
	renderTableHead: (results: ResultsObject<T>) => JSX.Element;
	renderTableRow: (
		results: ResultsObject<T>,
		item: T,
		dragProvided: DraggableProvided | null,
		index: number,
	) => JSX.Element;
	renderCustomSelectActionButton?: (ids: (number | string)[]) => JSX.Element;
	rowsPerPageOptions?: number[];
	children?: ReactNode;
}
const _DEFAULT_ROWS_PER_PAGE_OPTIONS = [25, 50, 100];
const _DEFAULT_SORT_OPTIONS = [];

interface ITableState<T> {
	selectedItems: (number | string)[];
	page: number;
	limit: number;
	query: string;
	selectedSortOption: SortOption<T> | null;
	showFiltered: boolean;
	selectedDropdownFilter: string;
	selectedMultiSelectFilter: (string | number)[];
}

const applyFilters = <T extends object>(
	items: T[],
	query: string,
	customSearchFilter?: (item: T, query: string) => boolean,
	dropdownFilter?: IDropdownFilterConfig<T>,
	selectedDropdownFilter?: string | null,
	multiselectFilter?: IMulitSelectFilterConfig<T>,
	selectedMultiSelectFilter?: (string | number)[],
	properties?: (keyof T | string[])[],
): T[] => {
	if (!query && !dropdownFilter && !multiselectFilter) {
		return [...items];
	}

	const searchFilteredItems = items.filter((item) => {
		const queryNormalized = query?.toLowerCase();
		const containsQuery = properties?.some((path) => {
			const value = get(item, path);
			const stringifiedValue = String(value);
			return stringifiedValue.toLowerCase().includes(queryNormalized);
		});

		return (
			containsQuery ||
			(customSearchFilter ? customSearchFilter(item, query) : false)
		);
	});
	if (dropdownFilter && selectedDropdownFilter?.length) {
		return searchFilteredItems.filter((item) => {
			const dropdownFilterValue = get(item, dropdownFilter.key);
			return dropdownFilterValue === selectedDropdownFilter;
		});
	}
	if (multiselectFilter && selectedMultiSelectFilter?.length) {
		return searchFilteredItems.filter((item) => {
			const comparisonValue = multiselectFilter.getComparisonValue(item);
			if (Array.isArray(comparisonValue)) {
				return selectedMultiSelectFilter.every((selectedValue) => comparisonValue.includes(selectedValue));
			}
			return selectedMultiSelectFilter.includes(comparisonValue);
		});
	}
	return searchFilteredItems;
};

const applyPagination = <T extends object>(items: T[], page: number, limit: number): T[] => {
	return items.slice(page * limit, page * limit + limit);
};

const applyGroupFilter = <T extends object>(
	items: T[],
	filter: keyof T | ((item: T) => boolean),
	showFiltered: boolean,
): T[] => {
	return items.filter((item) => {
		if (typeof filter === "function") {
			return showFiltered ? filter(item) : !filter(item);
		}
			return !item[filter];
	});
};

const applySort = <T extends object>(items: T[], sortOption: SortOption<T>): T[] => {
	const caseInsensitiveOrderBy = (item: T) => {
		const value = get(item, sortOption.key);
		if (_.isString(value)) {
			return value.toLowerCase();
		}
		return value !== null && value !== undefined && value;
	};
	return _.orderBy(items, [caseInsensitiveOrderBy], [sortOption.direction]);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
	},
	queryField: {
		width: 500
	},
	bulkOperations: {
		position: 'relative'
	},
	bulkActions: {
		paddingLeft: 4,
		paddingRight: 4,
		marginTop: 6,
		position: 'absolute',
		width: '100%',
		zIndex: 2,
		backgroundColor: theme.palette.background.default
	},
	bulkAction: {
		marginLeft: theme.spacing(2)
	},
	avatar: {
		height: 42,
		width: 42,
		marginRight: theme.spacing(1)
	},
	deleted: {
		backgroundColor: theme.name === THEMES.DARK
			? theme.palette.warning.dark
			: theme.palette.warning.light
	}
}));

const Results = <T extends object>({
	className,
	items,
	loading = false,
	itemName,
	itemNamePlural,
	filterProperties,
	customSearchFilter: customSearchFilters,
	dropdownFilter,
	dropdownFilterLabel,
	multiselectFilter,
	multiselectFilterLabel,
	groupFilter,
	filteredLabel,
	selectedKey = "id",
	isItemSelectable = () => true,
	selectedItemsAction,
	selectedItemsActionLabel = "Delete",
	disableSelectedItemsActions = false,
	disableSearch = false,
	disableSort = false,
	disablePersistedState = false,
	sortOptions = _DEFAULT_SORT_OPTIONS,
	onReorder = (_reorderedItems) => null,
	renderTableHead,
	renderTableRow,
	renderCustomSelectActionButton,
	rowsPerPageOptions = _DEFAULT_ROWS_PER_PAGE_OPTIONS,
	children,
	...rest
}: ResultsProps<T>) => {
	const classes = useStyles();
	const [selectedItems, setSelectedItems] = useState<(number | string)[]>([]);
	const [page, setPage] = useState<number>(0);
	const [limit, setLimit] = useState<number>(rowsPerPageOptions[0]);
	const [query, setQuery] = useState<string>('');
	const [selectedSortOption, setSelectedSortOption] =
		useState<SortOption<T> | null>(sortOptions?.length ? sortOptions[0] : null);
	const [showFiltered, setShowFiltered] = useState<boolean>(false);
	const [selectedDropdownFilter, setSelectedDropdownFilter] = useState<string>("");
	const [selectedMultiSelectFilter, setSelectedMultiSelectFilter] = useState<(string | number)[]>([]);

	const tableState = usePersistedState<ITableState<T>>(`${PORTAL_TABLE_STATE}/${itemName}`, {
		selectedItems,
		page,
		limit,
		query,
		selectedSortOption,
		showFiltered,
		selectedDropdownFilter,
		selectedMultiSelectFilter,
	});

	// go back to page 1 if items change
	useEffect(() => {
		setPage(0);
	}, [items]);

	useEffect(() => {
		if (!tableState || disablePersistedState) {
			return;
		}
		setSelectedItems(tableState.selectedItems);
		setPage(tableState.page);
		setLimit(tableState.limit);
		setQuery(tableState.query);
		setSelectedSortOption(
			sortOptions?.find((sortOption) => tableState.selectedSortOption?.label === sortOption.label)
				?? sortOptions[0]
				?? null
		);
		setShowFiltered(tableState.showFiltered);
		setSelectedDropdownFilter(tableState.selectedDropdownFilter);
	}, [tableState]);

	const filterSelected = useCallback(() => {
		// Key items by selected key
		const itemDict = _.keyBy(items, (item) => get(item, selectedKey));
		// filter selected items for items that do not exist
		const filteredSelected = selectedItems.filter((item) => {
			return itemDict[item];
		});
		// Check if filtered selected length is not the same as selected length
		if (filteredSelected.length !== selectedItems.length) {
			// Reset selected items
			setSelectedItems(filteredSelected);
		}
	}, [items, selectedItems, selectedKey]);
	useEffect(filterSelected, [items]);

	const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
		event.persist();
		setQuery(event.target.value);
		setPage(0);
	};

	const handleSelectAllItems = (event: ChangeEvent<HTMLInputElement>): void => {
		const indeterminate = event.target.dataset?.indeterminate === "true";
		const selectableItems = items.filter(isItemSelectable);
		setSelectedItems(event.target.checked && !indeterminate
			? selectableItems.map((item) => get(item, selectedKey))
			: []);
	};

	const handleSelectOneItem = (event: ChangeEvent<HTMLInputElement>, itemId: number | string): void => {
		if (!selectedItems.includes(itemId)) {
			setSelectedItems((prevSelected) => [...prevSelected, itemId]);
		} else {
			setSelectedItems((prevSelected) => prevSelected.filter((id) => id !== itemId));
		}
	};

	const handlePageChange = (event: any, newPage: number): void => {
		setPage(newPage);
	};

	const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
		// tslint:disable-next-line: radix
		setLimit(parseInt(event.target.value));
	};

	const groupedItems = groupFilter ? applyGroupFilter<T>(items, groupFilter, showFiltered) : items;
	const filteredItems = applyFilters<T>(
		groupedItems,
		query,
		customSearchFilters,
		dropdownFilter,
		selectedDropdownFilter,
		multiselectFilter,
		selectedMultiSelectFilter,
		filterProperties
	);
	const sortedItems = selectedSortOption ? applySort<T>(filteredItems, selectedSortOption) : filteredItems;
	const sortedGroupedItems = _.orderBy(sortedItems, groupFilter, "desc");

	const paginatedItems = applyPagination<T>(sortedGroupedItems, page, limit);
	const enableBulkOperations = !disableSelectedItemsActions && selectedItems.length > 0;
	const selectedSomeItems = selectedItems.length > 0 && selectedItems.length < items.length;
	const selectedAllItems = selectedItems.length === items.length;

	const results = {
		selectedAllItems,
		handleSelectAllItems,
		selectedSomeItems,
		handleSelectOneItem,
		paginatedItems,
		selectedItems,
		deletedClass: classes.deleted,
	};
	const reorderOptions = sortOptions?.filter(({ reorder }) => reorder).map(({ label }) => label).join(", ");
	return (
		<Card className={clsx(classes.root, className)} {...rest}>
			{children}
			{(!disableSearch || !disableSort) && (
				<Box p={2} minHeight={56} display="flex" alignItems="center">
					{!disableSearch && (
						<TextField
							className={classes.queryField}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SvgIcon fontSize="small" color="action">
											<SearchIcon />
										</SvgIcon>
									</InputAdornment>
								),
							}}
							onChange={handleQueryChange}
							placeholder={`Search ${itemNamePlural.toLowerCase()}`}
							value={query}
							variant="outlined"
						/>
					)}
					{dropdownFilter && (
						<FormControl style={{ minWidth: 120, marginLeft: 10 }} variant="outlined">
							<InputLabel id="dropdowm-select-filter-label">
								{dropdownFilterLabel}
							</InputLabel>
							<Select
								name="selectedDropdownFilter"
								onChange={(event: any) =>
									setSelectedDropdownFilter(event.target.value)
								}
								value={selectedDropdownFilter}
								labelId="dropdowm-select-filter-label"
							>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								{dropdownFilter.options.map(({ value, label }) => (
									<MenuItem key={value} value={value}>
										{label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					{multiselectFilter && (
						<Box p={2} width={400}>
							<MultiselectWithAll
								label={multiselectFilterLabel}
								name={multiselectFilterLabel}
								options={multiselectFilter.options}
								disableLabelSort
								onChange={(event: any) => {
									const selection = event.target.value;
									setSelectedMultiSelectFilter(selection);
								}}
								selections={selectedMultiSelectFilter}
								compareValue={(item) => item}
								getValue={(item) => item.value}
								getLabel={(item) => item.label}
								error={false}
							/>
						</Box>
					)}
					{filteredLabel && (
						<Box p={2}>
							<FormControlLabel
								control={(
									<Checkbox
										checked={showFiltered}
										onChange={() => setShowFiltered(!showFiltered)}
										value={showFiltered}
										name="showFiltered"
									/>
								)}
								label={filteredLabel}
							/>
						</Box>
					)}
					<Box p={2}>
						{reorderOptions && !!showFiltered && (
							<FormHelperText style={{ padding: 10 }}>
								Clear filter to drag-n-drop.
							</FormHelperText>
						)}
						{reorderOptions && !!query && (
							<FormHelperText style={{ padding: 10 }}>
								Clear search query to drag-n-drop.
							</FormHelperText>
						)}
					</Box>
					<Box flexGrow={1} />
					{!disableSort && (
						<Box p={2}>
							{selectedSortOption && (
								<Grid container justifyContent="flex-end" alignItems="center">
									{reorderOptions && !selectedSortOption.reorder && (
										<FormHelperText style={{ padding: 10 }}>
											Sort by {reorderOptions} to drag-n-drop.
										</FormHelperText>
									)}
									<InputLabel id="sortBy">Sort By&nbsp;</InputLabel>
									<Select
										labelId="sortBy"
										name="sort"
										onChange={(event: any) => {
											const option = sortOptions?.find(
												(sortOption) =>
													sortOption.label === event.target.value.label,
											);
											setSelectedSortOption(option ?? null);
										}}
										value={selectedSortOption}
										variant="outlined"
									>
										{sortOptions?.map((option) => (
											<MenuItem
												key={`${
													Array.isArray(option.key)
														? option.key.join()
														: String(option.key)
												}|${option.direction}`}
												value={option as any}
											>
												{option.label}
											</MenuItem>
										))}
									</Select>
								</Grid>
							)}
						</Box>
					)}
				</Box>
			)}
			{enableBulkOperations && (
				<div className={classes.bulkOperations}>
					<div className={classes.bulkActions}>
						<Checkbox
							checked={selectedAllItems}
							indeterminate={selectedSomeItems}
							onChange={handleSelectAllItems}
						/>
						{renderCustomSelectActionButton ? (
							renderCustomSelectActionButton(selectedItems)
						) : (
							<Button
								variant="outlined"
								disabled={disableSelectedItemsActions || loading}
								className={classes.bulkAction}
								onClick={async () => {
									if (selectedItemsAction) {
										await selectedItemsAction(selectedItems);
									}
									setSelectedItems([]);
								}}
							>
								{selectedItemsActionLabel}
							</Button>
						)}
					</div>
				</div>
			)}
			<PerfectScrollbar>
				<Box minWidth={700}>
					{loading && (
						<>
							<Table>
								<TableHead>{renderTableHead(results)}</TableHead>
							</Table>
							<LoadingScreen />
						</>
					)}
					{!loading && (
						<Table>
							<TableHead>{renderTableHead(results)}</TableHead>
							{selectedSortOption?.reorder && !query && !showFiltered ? (
								<DragDropContext
									onDragEnd={(dropResult, _provided) => {
										if (!dropResult.destination) return;
										const [draggedItem] = sortedItems.splice(
											dropResult.source.index,
											1,
										);
										sortedItems.splice(
											dropResult.destination.index,
											0,
											draggedItem,
										);
										onReorder(sortedItems);
									}}
								>
									<Droppable droppableId="results">
										{(dropProvided) => (
											<TableBody
												ref={dropProvided.innerRef}
												{...dropProvided.droppableProps}
											>
												{paginatedItems.map((item, index) => (
													<Draggable
														key={get(item, selectedKey).toString()}
														draggableId={get(item, selectedKey).toString()}
														index={index}
													>
														{(dragProvided) =>
															renderTableRow(results, item, dragProvided, index)
														}
													</Draggable>
												))}
												{dropProvided.placeholder}
											</TableBody>
										)}
									</Droppable>
								</DragDropContext>
							) : (
								<TableBody>
									{paginatedItems.map((item, index) =>
										renderTableRow(results, item, null, index),
									)}
								</TableBody>
							)}
						</Table>
					)}
				</Box>
			</PerfectScrollbar>
			<TablePagination
				component="div"
				count={filteredItems.length}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleLimitChange}
				page={page}
				rowsPerPage={limit}
				rowsPerPageOptions={rowsPerPageOptions}
			/>
		</Card>
	);
};

Results.defaultProps = {
	items: []
};

export default Results;
