import { IAddOn, IAddOnCreate, IAddOnResponse, IAddOnUpdate, IWashType, MultiTenantFindOneOptions } from "amp";

import { useCallback } from "react";
import { useNavigate } from "react-router";
import { EEndpointType } from "amp";
import useConfirmDelete from "src/hooks/useConfirmDelete";
import axios from "src/utils/axios";

export enum EAddOnType {
	WashUpgrade = "Wash upgrade",
	AdditionalService = "Additional Service",
	AdditionalItem = "Additional Item",
}

export enum EDisplayTileType {
	Image = "Image",
	Text = "Text",
}
export type WashTypeIdAndLabel = Pick<IWashType, "name" | "external_id__c">;

const ADD_ON_TYPE_OPTIONS = [EAddOnType.AdditionalItem, EAddOnType.AdditionalService, EAddOnType.WashUpgrade];
const DISPLAY_TILE_TYPE_OPTIONS = [EDisplayTileType.Image, EDisplayTileType.Text];

const PAGE_NAME = "Add Ons";

const PORTAL_URL = "/app/management/add-ons";
const PORTAL_URL_CREATE = PORTAL_URL + "/create";
const PORTAL_URL_EDIT = PORTAL_URL + "/:id/edit";

const ITEM_NAME_PLURAL = "Add Ons";
const ITEM_NAME = "Add On";

const _API_URL = `${EEndpointType.User}/add-ons/`;
const _post = <T extends {}, Y>(endpoint: string, body: T): Promise<Y> => axios.post<Y>(_API_URL + endpoint, body).then(x => x.data);

const getAll = (findOptions?: MultiTenantFindOneOptions<IAddOn>) => _post<MultiTenantFindOneOptions<IAddOn>, IAddOn[]>("get", findOptions ?? {});
const getById = (id: number | string, findOptions?: MultiTenantFindOneOptions<IAddOn>) => _post<MultiTenantFindOneOptions<IAddOn>, IAddOn>(`get/${id}`, findOptions ?? {});

const create = <T extends IAddOnCreate>(
	insert: T
): Promise<IAddOnResponse> => _post<T, IAddOnResponse>("create", insert);

const update = <T extends IAddOnUpdate>(
	updates: T[]
): Promise<IAddOnResponse[]> => _post<T[], IAddOnResponse[]>("update", updates);
const updateOne = <T extends IAddOnUpdate>(
	addOn: T
): Promise<IAddOnResponse> => update([addOn]).then(x => x[0]);

const getPortalUrlEdit = (id?: number | string) => id
	? PORTAL_URL_EDIT.replace(":id", id.toString())
	: PORTAL_URL_EDIT;

const useGoTo = () => {
	const navigate = useNavigate();
	const goToCreate = useCallback(() => navigate(PORTAL_URL_CREATE), [navigate]);
	const goToList = useCallback(() => navigate(PORTAL_URL), [navigate]);
	return { goToCreate, goToList };
};

const getWashTypes = () => {
	return axios.post<{ washTypes: WashTypeIdAndLabel[] }>(`${EEndpointType.User}/wash-types/get-wash-types`, 
		{ 
			select: ["name", "external_id__c"], 
			where: { service__c: "Car Wash" }
		}).then(x => x.data.washTypes);
};

export const AddOnService = {
	PAGE_NAME,
	ITEM_NAME_PLURAL,
	ITEM_NAME,
	PORTAL_URL_CREATE,
	PORTAL_URL_EDIT,
	PORTAL_URL,
	ADD_ON_TYPE_OPTIONS,
	DISPLAY_TILE_TYPE_OPTIONS,

	useGoTo,
	getPortalUrlEdit,
	getAll,
	getById,
	create,
	updateOne,
	getWashTypes,
};