import {
	Checkbox,
	FormControl,
	FormHelperText,
	InputLabel,
	ListItemText,
	MenuItem,
	MenuProps as IMenuProps,
	Select,
} from "@material-ui/core";
import { keyBy, sortBy } from "lodash";
import React, { ChangeEvent, ReactNode, useMemo } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps: Partial<IMenuProps> = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	variant: "menu",
	getContentAnchorEl: null,
};

export interface MultiselectProps<T, K> {
	label: string;
	name: string;
	options: T[];
	disableLabelSort?: boolean;
	getValue: (option: T) => K;
	getLabel: (option: T) => string;
	compareValue: (option: K) => string | number;
	disableSelection?: (option: T) => boolean;
	selections: K[];
	onChange: (event: ChangeEvent<{ name?: string; value: unknown }>) => void;
	error: boolean;
	disabled?: boolean;
	errorText?: string;
	required?: boolean;
	renderValue?: (selectedOptions: T[]) => ReactNode;
	children?: ReactNode | ReactNode[];
}

function MultiselectInput<T, K>({
	label,
	name,
	options,
	disableLabelSort = false,
	getValue,
	getLabel,
	compareValue,
	disableSelection,
	selections,
	onChange,
	error,
	disabled = false,
	errorText,
	required,
	renderValue,
	children,
}: MultiselectProps<T, K>) {
	const sortedOptions = useMemo(
		() =>
			sortBy(options, (option) =>
				option && !disableLabelSort ? getLabel(option).toUpperCase() : "",
			),
		[options],
	);

	const hashMapOptions = useMemo(() => {
		return keyBy(options, (option) => compareValue(getValue(option)));
	}, [options]);

	const selectionsMappedToOptions = useMemo(() => {
		return selections.map((selection) => {
			const mappedOption = hashMapOptions[compareValue(selection)];
			return mappedOption ? getValue(mappedOption) : selection;
		});
	}, [selections, options]);

	function defaultRenderValue(selectedOptions: T[]): string {
		return selectedOptions.length > 0
			? selectedOptions
					.map((selectedOption) => {
						return getLabel(selectedOption);
					})
					.join(", ")
			: "None";
	}

	function handleRenderValue(selectedOptions: K[]): ReactNode {
		const mappedOptions = selectedOptions
			.map((selectedOption) => hashMapOptions[compareValue(selectedOption)])
			.filter((mappedOption) => mappedOption !== undefined);
		if (!!renderValue) {
			return renderValue(mappedOptions);
		}
		return defaultRenderValue(mappedOptions);
	}

	return (
		<FormControl error={error} style={{ width: "100%" }}>
			<InputLabel variant="outlined" shrink={true} required={required}>
				{label}
			</InputLabel>
			<Select
				fullWidth
				label={label}
				name={name}
				disabled={disabled}
				onChange={onChange}
				required={required}
				multiple
				value={selectionsMappedToOptions}
				renderValue={handleRenderValue}
				variant="outlined"
				MenuProps={MenuProps}
				displayEmpty
			>
				{children}
				{sortedOptions.map((option, index) => (
					<MenuItem key={index} value={getValue(option) as any} disabled={disableSelection?.(option) ?? false}>
						<Checkbox
							checked={
								!!selectionsMappedToOptions.find(
									(selection) => selection === getValue(option),
								)
							}
							disabled={disableSelection?.(option) ?? false}
						/>
						<ListItemText primary={getLabel(option)} />
					</MenuItem>
				))}
			</Select>
			{error && errorText && <FormHelperText error>{errorText}</FormHelperText>}
		</FormControl>
	);
}

export default MultiselectInput;
