import {
	EEndpointType,
	ICouponsResponse,
	ICreatePromotionCodeRequest,
	IPromotionCode,
	IUpdatePromotionCodeRequest,
	XCreatePromotionCodeResponse,
} from "amp";
import axios from "src/utils/axios";
import Stripe from "stripe";
import { PromoCodeRoutes } from "./promo-code.routes";

export interface IPromoCodeBatch {
	firstTimeUseOnly: boolean;
	expirationDate: Date | null;
	maxRedemptions: number;
	fileName: string;
	numberOfCodes: number;
	prefix: string;
	suffixCharCount: number;
	coupon: Stripe.Coupon | null;
	minOrderValue: number | null;
}

const BILLING_CSR_ENDPOINT = `${EEndpointType.Csr}/billing`;

const PAGE_NAME = "Promo Code Management";
const ITEM_NAME = "Promo Code";
const ITEM_NAME_PLURAL = "Promo Codes";

const BASE_URL = `${EEndpointType.Csr}/promotion-codes`;
const CREATE_URL = `${BASE_URL}/create-promotion-codes`;
const UPDATE_URL = `${BASE_URL}/update-promotion-codes`;
const GET_ALL_URL = `${BASE_URL}/get-all-promotion-codes`;
const GET_URL = `${BASE_URL}/get-promotion-code`;

const getPortalUrlEdit = (id?: number | string) =>
	id
		? PromoCodeRoutes.EDIT_ROUTE.replace(":id", id.toString())
		: PromoCodeRoutes.EDIT_ROUTE;

const archive = async (id: number): Promise<void> => {};
const unarchive = async (id: number): Promise<void> => {};

const getAll = async (): Promise<IPromotionCode[]> => {
	return (await axios.get<IPromotionCode[]>(GET_ALL_URL)).data;
};

const get = async (id: number): Promise<IPromotionCode> => {
	return (await axios.post<IPromotionCode>(GET_URL, { id })).data;
};

const create = async (
	request: ICreatePromotionCodeRequest,
): Promise<void> => {};

const update = async (
	request: IUpdatePromotionCodeRequest,
): Promise<void> => {};

const getCoupons = async (): Promise<Stripe.Coupon[]> => {
	const {
		data: { coupons },
	} = await axios.get<ICouponsResponse>(`${BILLING_CSR_ENDPOINT}/coupons`);
	return coupons;
};

const handleCreatePromoCodes = async (
	promoCodeRequest: ICreatePromotionCodeRequest,
): Promise<string[]> => {
	const result = await axios.post<XCreatePromotionCodeResponse>(
		`${CREATE_URL}`,
		promoCodeRequest,
	);
	return result.data.map((promotionCode) => promotionCode.code);
};

const getCouponLabel = (coupon: Stripe.Coupon) => {
	let duration: string;
	if (coupon.duration === "repeating") {
		const timeFrame = coupon.duration_in_months ?? 0;
		const months = timeFrame === 1 ? "month" : "months";
		duration = `for ${timeFrame} ${months}`;
	} else {
		duration = coupon.duration === "forever" ? "forever" : "one time";
	}
	if (coupon.percent_off) {
		return `${coupon.name} (${coupon.percent_off}% off ${duration})`;
	}
	const amountOff = coupon.amount_off
		? (coupon.amount_off / 100).toFixed(2)
		: 0;
	return `${coupon.name} ($${amountOff} off ${duration})`;
};

const initialFormValues: IPromoCodeBatch = {
	firstTimeUseOnly: true,
	expirationDate: null,
	maxRedemptions: null,
	fileName: `Promo Codes - ${new Date().toDateString()}`,
	numberOfCodes: 0,
	prefix: "",
	suffixCharCount: 5,
	minOrderValue: 0,
	coupon: null,
};

const PromoCodeService = {
	ITEM_NAME,
	ITEM_NAME_PLURAL,
	PAGE_NAME,
	initialFormValues,
	handleCreatePromoCodes,
	getCoupons,
	getCouponLabel,
	create,
	update,
	archive,
	unarchive,
	getAll,
	get,
	getPortalUrlEdit,
};

export default PromoCodeService;
