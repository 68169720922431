import {
	ChangeEvent,
	useEffect,
	useState,
	ReactNode,
	FC,
	Children,
} from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MaterialTabs, {
	TabsProps as MaterialTabsProps,
} from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Add as AddIcon, Close as CloseIcon } from "@material-ui/icons";
import { Button, IconButton } from "@material-ui/core";
import LoadingScreen from "./LoadingScreen";
import { useSearchParams } from "react-router-dom";

interface TabPanelProps {
	children?: ReactNode;
	index: any;
	value: any;
}

export function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		width: "100%",
	},
	tabWithClose: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		alignItems: "center",
	},
	addNewButton: {
		color: theme.palette.text.secondary,
		minWidth: "100px",
	},
	tabsContainer: {
		display: "flex",
		width: "100%",
	},
}));

export interface TabItem {
	key: string | number;
	label: string;
	cache?: boolean;
	preload?: boolean;
}

export interface SimpleTabsProps extends Partial<TabsProps> {
	tabItems: TabItem[];
	children: (selectedTabItem: TabItem, index: number) => ReactNode;
	updateUrl?: boolean;
	loading?: boolean;
	cache?: boolean;
	preload?: boolean;
}

export const useTabs = (tabItems: TabItem[], updateUrl: boolean) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [value, setValue] = useState<number>(() => {
		const tabIndex = tabItems.findIndex(
			({ key }) => searchParams.get("tab") === key.toString(),
		);
		return tabIndex === -1 ? 0 : tabIndex;
	});
	// Update URL query params when tab changed
	useEffect(() => {
		if (updateUrl && value) {
			setSearchParams({ tab: tabItems[value]?.key.toString() });
		} else if (updateUrl) {
			setSearchParams({ tab: tabItems[0].key.toString() }, { replace: true });
		}
	}, [value, updateUrl]);

	const handleChange = (_event: ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	const manuallySetValue = (newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		const tabIndex = tabItems.findIndex(
			({ key }) => searchParams.get("tab") === key.toString(),
		);
		if (tabIndex !== -1) {
			setValue(tabIndex);
		}
	}, [tabItems, searchParams]);

	return {
		value,
		handleChange,
		manuallySetValue,
	};
};
// This component inspired by Material UI example https://material-ui.com/components/tabs/#simple-tabs
export function SimpleTabs({
	tabItems,
	children,
	updateUrl = true,
	loading = false,
	cache = false,
	preload = false,
	...props
}: SimpleTabsProps) {
	const classes = useStyles();
	const { value, handleChange } = useTabs(tabItems, updateUrl);
	const [visitedTabs, setVistedTabs] = useState<Record<number, boolean>>({});
	
	useEffect(() => {
		if (!visitedTabs[value]) {
			setVistedTabs((prevState) => ({ ...prevState, [value]: true }));
		}
	}, [value]);
	return (
		<div className={classes.root}>
			{tabItems.length > 1 && (
				<Tabs
					value={value}
					handleChange={handleChange}
					tabItems={tabItems}
					{...props}
				/>
			)}
			<LoadingScreen loading={loading}>
				<TabPanel value={value} index={value}>
					{tabItems.map((tabItem, tabItemIndex) =>
						(
							// if preload, always load tab
							(tabItem.preload ?? preload) ||
							// if cache, load tab if previously or currently visited
							(tabItem.cache ?? cache) && (visitedTabs[tabItemIndex] || tabItemIndex === value)
						) ? (
							<CachedTab key={tabItem.key} show={tabItemIndex === value}>
								{children(tabItem, tabItemIndex)}
							</CachedTab>
						) : (
							// only load tab if currently visited
							<div key={tabItem.key}>
								{tabItemIndex === value && children(tabItem, tabItemIndex)}
							</div>
						),
					)}
				</TabPanel>
			</LoadingScreen>
		</div>
	);
}

export default SimpleTabs;

const CachedTab: FC<{ show: boolean }> = ({ children, show }) => (
	<div style={{ display: show ? "block" : "none" }}>{children}</div>
);

interface SimpleTabsWithHideProps {
	tabItems: TabItem[];
	children: ReactNode;
	updateUrl?: boolean;
}
export const SimpleTabsWithHide = ({
	children,
	tabItems,
	updateUrl = true,
}: SimpleTabsWithHideProps) => {
	const classes = useStyles();
	const { value, handleChange } = useTabs(tabItems, updateUrl);
	return (
		<div className={classes.root}>
			<Tabs value={value} handleChange={handleChange} tabItems={tabItems} />
			<TabPanel value={value} index={value}>
				{Children.map(children, (child, index) => {
					return (
						<div style={{ display: index === value ? "block" : "none" }}>
							{child}
						</div>
					);
				})}
			</TabPanel>
		</div>
	);
};

interface TabsProps extends Partial<MaterialTabsProps> {
	value: any;
	handleChange: any;
	tabItems: TabItem[];
	addTabLabel?: string;
	scrollable?: boolean;
	readOnly?: boolean;
	addTab?: (tabCount: number) => void;
	removeTab?: (index: number, currentSelection: number) => void;
}

export const Tabs = ({
	value,
	handleChange,
	tabItems,
	addTabLabel = "Add Tab",
	scrollable = false,
	readOnly = false,
	addTab,
	removeTab,
	...rest
}: TabsProps) => {
	const classes = useStyles();

	return (
		<div className={classes.tabsContainer}>
			<MaterialTabs
				value={value}
				onChange={handleChange}
				indicatorColor="secondary"
				textColor="secondary"
				aria-label="simple tabs"
				variant={scrollable ? "scrollable" : "standard"}
				scrollButtons={scrollable ? "on" : "off"}
				{...rest}
			>
				{tabItems.map(({ key, label }, index) => (
					<Tab
						key={key}
						label={
							removeTab && !readOnly ? (
								<TabWithCloseIcon
									label={label}
									value={value}
									index={index}
									className={classes.tabWithClose}
									removeTab={removeTab}
								/>
							) : (
								label
							)
						}
						{...a11yProps(index)}
					/>
				))}
			</MaterialTabs>
			{addTab && !readOnly ? (
				<AddTabButton
					onClick={() => addTab(tabItems.length)}
					className={classes.addNewButton}
				>
					<AddIcon color="secondary" />
					{addTabLabel}
				</AddTabButton>
			) : null}
		</div>
	);
};

const AddTabButton = ({ className, onClick, children }) => (
	<Button
		size="small"
		className={className}
		onClick={onClick}
		children={children}
	/>
);

const TabWithCloseIcon = ({ label, value, index, className, removeTab }) => (
	<div className={index !== 0 ? className : ""}>
		{label}
		{removeTab && index !== 0 ? (
			<IconButton
				size="small"
				onClick={(e) => {
					e.stopPropagation();
					removeTab(index, value);
				}}
			>
				<CloseIcon />
			</IconButton>
		) : null}
	</div>
);
