import { Fragment, Suspense, lazy } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";
import { POPUP_PATH } from "./views/popups/constants";
import CreateTenantGuard from "./components/CreateTenantGuard";
import { REPORT_DASHBOARD_PATH } from "src/views/report-dashboard";
import { FleetAccountsService } from "./views/fleet-accounts/fleet-accounts.service";
import { combineComponents } from "./utils/combineComponents";
import {
	appSections,
	fleetAdminSections,
	siteLeadSections,
} from "./layouts/DashboardLayout/NavBar";
import plans from "./views/plans/services/plans.service";
import { AddOnService } from "./views/add-ons/add-on.service";
import { GamesRoutes } from "./views/games/games-routes";
import { CodeBucketService } from "./views/code-buckets/code-bucket.service";
import { PriceGroupService } from "./views/price-groups/price-group.service";
import { AnimationRoutes } from "./views/animations/animations.routes";
import { BrandImageRoutes } from "./views/brand-images/brand-images.routes";
import { EmployeeRoutes } from "./views/employee/employee.routes";
import { IntegrationsRoutes } from "./views/integrations/integrations.routes";
import { LegacyMembersRoutes } from "./views/user-management/LegacyMembers/legacyMember.routes";
import { SurveyRoutes } from "./views/survey/survey.routes";
import { ConfigurablePagesRoutes } from "./views/configurable-pages/configurable-pages.routes";
import { SmsAutomatedReplyRoutes } from "./views/sms-automated-reply/sms-automated-reply.routes";
import { BrandImageCategoriesService } from "./views/brand-images/brand-image-categories.service";
import { KioskRoutes } from "./views/kiosks/kiosk.routes";
import { CustomerJourneyService } from "./views/customer-journeys/customerJourneyService";
import { escapeRegExp } from "lodash";
import ErrorBoundary from "./components/ErrorBoundary";
import LoadingScreen from "./components/LoadingScreen";
import { LoyaltyRoutes } from "./views/loyalty/loyalty.routes";
import { EdgeDeviceRoutes } from "./views/edge-device/edge-device.routes";
import { THEME_ROUTES } from "./views/themes/theme-routes";
import { KioskTransactionsRoutes } from "./views/kiosk-transactions/kiosk-transactions.routes";
import { lanesRoutes } from "./views/lanes/lanes.routes";
import {
	UNCONFIRMED_USERS_BASE_ROUTE,
	USERS_BASE_ROUTE,
	USERS_EDIT_ROUTE,
} from "./views/user-management/UserEditView/user.routes";
import { ControllerRoutes } from "./views/tunnel-controller/controller.routes";
import { CameraRoutes } from "./views/cameras/camera.routes";
import { GateRoutes } from "./views/gates/gates.routes";
import { WashModeRoutes } from "./views/wash-mode/wash-mode.routes";
import { ScanResultsRoutes } from "./views/scan-results/scan-results.routes";
import { TransactionsRoutes } from "./views/transactions/transactions.routes";
import {
	FleetGuard,
	ManagementGuard,
	SiteLeadGuard,
} from "./components/AppGuard";
import { FleetAdminPortal } from "./views/fleet-admin";
import {
	SITE_LEAD_USERS_BASE_ROUTE,
	SITE_LEAD_USERS_EDIT_ROUTE,
	SITE_LEAD_EMAIL_MIGRATION_ROUTE,
	SITE_LEAD_GUEST_USER_ACTIVITY_ROUTE,
	SITE_LEAD_KIOSK_TRANSACTIONS_ROUTE,
	SITE_LEAD_ALL_TRANSACTIONS_ROUTE,
} from "./views/site-lead/UserEditView/site-lead.service";
import { ContactRoutes } from "./views/contacts/contacts.routes";
import { RfidReaderRoutes } from "./views/rfid-readers/rfid-reader.routes";
import { PrepaidPromotionService } from "./views/prepaid-promotions/prepaid-promotion.service";
import { GiftCardRoutes } from "./views/gift-cards/gift-card.routes";
import { CouponRoutes } from "./views/coupon-management/coupons.routes";
import { PromoCodeRoutes } from "./views/promo-codes/promo-code.routes";
import { RetentionOfferRoutes } from "./views/retention-offers/retention-offer.routes";
import { CouponGroupRoutes } from "./views/coupon-management/coupon-groups/coupon-group-routes";

interface IRoute {
	path?: string;
	guard?: any;
	layout?: any;
	layoutProps?: any;
	component?: any;
	routes?: IRoute[];
}

type XRoutes = IRoute[];

const getRouteGuardsOrFragment = (guard?: any) => {
	if (!guard) {
		return Fragment;
	}
	if (Array.isArray(guard)) {
		return combineComponents(...guard);
	}
	return guard;
};

export const renderRoutes = (
	routes: XRoutes = [],
	parentRoutePath?: string,
): JSX.Element[] => {
	return routes.map((route) => {
		const Guard = getRouteGuardsOrFragment(route.guard);
		const Layout = route.layout || Fragment;
		const Component = route.component;
		// convert absolute paths to relative paths (react-router v5 -> v6)
		// this lets us avoid converting all routes to relative paths
		let routePath = route.path;
		if (parentRoutePath) {
			const parentRoutePathWithoutWildcard = parentRoutePath.replace(
				/\/\*$/,
				"",
			);
			const removeParentPathRegex = new RegExp(
				`^${escapeRegExp(parentRoutePathWithoutWildcard)}/`,
			);
			routePath = route.path?.replace(removeParentPathRegex, "");
		}
		return (
			<Route
				key={route.path}
				path={routePath}
				errorElement={<ErrorBoundary />}
				element={
					<Guard>
						<Layout {...route.layoutProps}>
							{route.routes ? (
								<Routes>{renderRoutes(route.routes, route.path)}</Routes>
							) : (
								<Suspense fallback={<LoadingScreen />}>
									<Component />
								</Suspense>
							)}
						</Layout>
					</Guard>
				}
			/>
		);
	});
};

export const routes: XRoutes = [
	{
		guard: GuestGuard,
		path: "/login",
		component: lazy(() => import("src/views/auth/LoginView")),
	},
	{
		path: "/login-unprotected",
		component: lazy(() => import("src/views/auth/LoginView")),
	},
	{
		path: "/app/*",
		guard: [AuthGuard, ManagementGuard],
		layout: DashboardLayout,
		layoutProps: { navSections: appSections },
		routes: [
			{
				path: GateRoutes.LIST_ROUTE,
				component: lazy(() => import("src/views/gates/GatesListView")),
			},
			{
				path: `${GateRoutes.FORM_ROUTE}/*`,
				component: lazy(() => import("src/views/gates/GatesEditView")),
			},
			{
				path: CameraRoutes.LIST_ROUTE,
				component: lazy(() => import("src/views/cameras/CameraListView")),
			},
			{
				path: `${CameraRoutes.FORM_ROUTE}/*`,
				component: lazy(() => import("src/views/cameras/CameraEditView")),
			},
			{
				path: ControllerRoutes.LIST_ROUTE,
				component: lazy(
					() => import("src/views/tunnel-controller/ControllerListView"),
				),
			},
			{
				path: `${ControllerRoutes.FORM_ROUTE}/*`,
				component: lazy(
					() => import("src/views/tunnel-controller/ControllerEditView"),
				),
			},
			{
				path: lanesRoutes.LIST_ROUTE,
				component: lazy(() => import("src/views/lanes/LanesListView")),
			},
			{
				path: `${lanesRoutes.FORM_ROUTE}/*`,
				component: lazy(() => import("src/views/lanes/LanesEditView")),
			},
			{
				path: KioskRoutes.LIST_ROUTE,
				component: lazy(() => import("src/views/kiosks/KioskGroupsListView")),
			},
			{
				path: `${KioskRoutes.FORM_KIOSK_ROUTE}/*`,
				component: lazy(() => import("src/views/kiosks/KiosksEditView")),
			},
			{
				path: `${KioskRoutes.CREATE_KIOSK_GROUP_ROUTE}/*`,
				component: lazy(() => import("src/views/kiosks/KioskGroupsEditView")),
			},
			{
				path: `${KioskRoutes.FORM_KIOSK_GROUP_ROUTE}/*`,
				component: lazy(() => import("src/views/kiosks/KioskGroupsEditView")),
			},
			{
				path: ConfigurablePagesRoutes.LIST_ROUTE,
				component: lazy(
					() =>
						import("src/views/configurable-pages/ConfigurablePagesListView"),
				),
			},
			{
				path: `${ConfigurablePagesRoutes.FORM_ROUTE}/*`,
				component: lazy(
					() =>
						import("src/views/configurable-pages/ConfigurablePagesEditView"),
				),
			},
			{
				path: REPORT_DASHBOARD_PATH,
				component: lazy(() => import("src/views/report-dashboard")),
			},
			{
				path: "/app/account",
				component: lazy(() => import("src/views/account/AccountView")),
			},
			{
				path: "/app/management/tenant/create",
				guard: CreateTenantGuard,
				component: lazy(() => import("src/views/tenant/CreateTenantView")),
			},
			{
				path: BrandImageRoutes.BRAND_IMAGES_LIST_ROUTE,
				component: lazy(
					() => import("src/views/brand-images/BrandImagesListView"),
				),
			},
			{
				path: BrandImageRoutes.BRAND_IMAGES_EDIT_ROUTE,
				component: lazy(
					() => import("src/views/brand-images/BrandImagesEditView"),
				),
			},
			{
				path: BrandImageRoutes.BRAND_IMAGES_CREATE_ROUTE,
				component: lazy(
					() => import("src/views/brand-images/BrandImagesEditView"),
				),
			},
			{
				path: BrandImageCategoriesService.BRAND_IMAGE_CATEGORIES_CREATE_ROUTE,
				component: lazy(
					() => import("src/views/brand-images/BrandImageCategoriesEditView"),
				),
			},
			{
				path: BrandImageCategoriesService.BRAND_IMAGE_CATEGORIES_EDIT_ROUTE,
				component: lazy(
					() => import("src/views/brand-images/BrandImageCategoriesEditView"),
				),
			},
			{
				path: AnimationRoutes.LIST_ROUTE,
				component: lazy(
					() => import("src/views/animations/AnimationsListView"),
				),
			},
			{
				path: `${AnimationRoutes.FORM_ROUTE}/*`,
				component: lazy(
					() => import("src/views/animations/AnimationsEditView"),
				),
			},
			{
				path: SmsAutomatedReplyRoutes.LIST_ROUTE,
				component: lazy(
					() =>
						import("src/views/sms-automated-reply/SmsAutomatedReplyListView"),
				),
			},
			{
				path: `${SmsAutomatedReplyRoutes.FORM_ROUTE}/*`,
				component: lazy(
					() =>
						import("src/views/sms-automated-reply/SmsAutomatedReplyEditView"),
				),
			},
			{
				path: SurveyRoutes.LIST_ROUTE,
				component: lazy(() => import("src/views/survey/SurveyListView")),
			},
			{
				path: `${SurveyRoutes.FORM_ROUTE}/*`,
				component: lazy(() => import("src/views/survey/SurveyEditView")),
			},
			{
				path: "/app/management/configurations/general",
				component: lazy(() => import("src/views/general/GeneralEditView")),
			},
			{
				path: "/app/management/configurations/attendant-mode",
				component: lazy(
					() => import("src/views/attendant-mode/AttendantModeEditView"),
				),
			},
			{
				path: "/app/management/configurations/menu-options",
				component: lazy(
					() => import("src/views/menu-options/MenuOptionsListView"),
				),
			},
			{
				path: "/app/management/configurations/menu-options/:menuOptionId/edit",
				component: lazy(
					() => import("src/views/menu-options/MenuOptionsEditView"),
				),
			},
			{
				path: "/app/management/configurations/menu-options/create",
				component: lazy(
					() => import("src/views/menu-options/MenuOptionsEditView"),
				),
			},
			{
				path: "/app/management/configurations/dynamic-dropdown-values",
				component: lazy(
					() =>
						import(
							"src/views/dynamic-dropdown-values/DynamicDropdownValuesView"
						),
				),
			},
			{
				path: "/app/management/codes/:codeBucketId?",
				component: lazy(() => import("src/views/code/CodeListView")),
			},
			{
				path: "/app/management/codes/:codeBucketId/:washTypeId/:washTypeName/:codeBucketName/:addOnIds?/:addOnNames?/edit",
				component: lazy(() => import("src/views/code/CodeEditView")),
			},
			{
				path: "/app/management/codes/:codeBucketId/:washTypeId/:washTypeName/:codeBucketName/:addOnIds?/:addOnNames?/details",
				component: lazy(() => import("src/views/code/CodeDetailsListView")),
			},
			{
				path: "/app/management/locations",
				component: lazy(() => import("src/views/location/LocationListView")),
			},
			{
				path: "/app/management/locations/:locationId/edit",
				component: lazy(() => import("src/views/location/LocationEditView")),
			},
			{
				path: "/app/management/locations/create",
				component: lazy(() => import("src/views/location/LocationEditView")),
			},
			{
				path: "/app/management/deals",
				component: lazy(() => import("src/views/deal/DealListView")),
			},
			{
				path: "/app/management/deals/edit/:dealId/:copy?",
				component: lazy(() => import("src/views/deal/DealEditView")),
			},
			{
				path: "/app/management/deals/create",
				component: lazy(() => import("src/views/deal/DealEditView")),
			},
			{
				path: `${PrepaidPromotionService.LIST_ROUTE}`,
				component: lazy(
					() => import("src/views/prepaid-promotions/PrepaidPromotionListView"),
				),
			},
			{
				path: `${PrepaidPromotionService.EDIT_ROUTE}/:copy?`,
				component: lazy(
					() => import("src/views/prepaid-promotions/PrepaidPromotionEditView"),
				),
			},
			{
				path: `${PrepaidPromotionService.CREATE_ROUTE}`,
				component: lazy(
					() => import("src/views/prepaid-promotions/PrepaidPromotionEditView"),
				),
			},
			{
				path: `${CustomerJourneyService.LIST_ROUTE}`,
				component: lazy(() => import("src/views/customer-journeys/index")),
			},
			{
				path: `${CustomerJourneyService.MESSAGE_TEMPLATE_FORM_ROUTE}`,
				component: lazy(
					() =>
						import(
							"src/views/customer-journeys/message-templates/MessageTemplatesEditView"
						),
				),
			},
			{
				path: `${POPUP_PATH}/list/:filter?/:filterLabel?`,
				component: lazy(() => import("src/views/popups/PopupsListView")),
			},

			{
				path: `${POPUP_PATH}/edit/:popupId/:copy?`,
				component: lazy(() => import("src/views/popups/PopupsEditView")),
			},
			{
				path: `${POPUP_PATH}/create`,
				component: lazy(() => import("src/views/popups/PopupsEditView")),
			},
			{
				path: "/app/management/tax-rates",
				component: lazy(() => import("src/views/tax-rates/TaxRatesListView")),
			},
			{
				path: "/app/management/employee-perks",
				component: lazy(
					() => import("src/views/employee-perks/EmployeePerksEditForm"),
				),
			},
			{
				path: USERS_BASE_ROUTE,
				component: lazy(() => import("src/views/user-management/UserListView")),
			},
			{
				path: UNCONFIRMED_USERS_BASE_ROUTE,
				component: lazy(
					() => import("src/views/user-management/UsersUnconfirmedListView"),
				),
			},
			{
				path: USERS_EDIT_ROUTE,
				component: lazy(() => import("src/views/user-management/UserEditView")),
			},
			{
				path: EmployeeRoutes.LIST_ROUTE,
				component: lazy(() => import("src/views/employee/EmployeeListView")),
			},
			{
				path: `${EmployeeRoutes.FORM_ROUTE}/*`,
				component: lazy(() => import("src/views/employee/EmployeeEditView")),
			},
			{
				path: "/app/management/users-and-accounts/user-email-migration",
				component: lazy(
					() => import("src/views/user-management/MigrateUserEmails"),
				),
			},
			{
				path: LegacyMembersRoutes.LIST_ROUTE,
				component: lazy(
					() =>
						import(
							"src/views/user-management/LegacyMembers/LegacyMembersListView"
						),
				),
			},
			{
				path: LegacyMembersRoutes.EDIT_ROUTE,
				component: lazy(
					() =>
						import(
							"src/views/user-management/LegacyMembers/LegacyMembersEditView"
						),
				),
			},
			{
				path: LegacyMembersRoutes.CREATE_ROUTE,
				component: lazy(
					() =>
						import(
							"src/views/user-management/LegacyMembers/LegacyMembersEditView"
						),
				),
			},
			{
				path: "/app/management/users-and-accounts/fleet-accounts",
				component: lazy(
					() => import("src/views/fleet-accounts/FleetAccountsListView"),
				),
			},
			{
				path: FleetAccountsService.PORTAL_URL_CREATE,
				component: lazy(
					() => import("src/views/fleet-accounts/FleetAccountsEditView"),
				),
			},
			{
				path: "/app/management/users-and-accounts/fleet-accounts/edit/:fleetAccountId",
				component: lazy(
					() => import("src/views/fleet-accounts/FleetAccountsEditView"),
				),
			},
			{
				path: "/app/management/users-and-accounts/guest-user-activity",
				component: lazy(
					() =>
						import("src/views/guest-user-activity/GuestUserActivityListView"),
				),
			},
			{
				path: KioskTransactionsRoutes.BASE_ROUTE,
				component: lazy(
					() =>
						import("src/views/kiosk-transactions/KioskTransactionsListView"),
				),
			},
			{
				path: TransactionsRoutes.BASE_ROUTE,
				component: lazy(
					() => import("src/views/transactions/TransactionsListView"),
				),
			},
			{
				path: "/app/management/wash-types",
				component: lazy(() => import("src/views/wash-type/WashTypeListView")),
			},
			{
				path: "/app/management/wash-types/create",
				component: lazy(() => import("src/views/wash-type/WashTypeEditView")),
			},
			{
				path: "/app/management/wash-types/:washTypeId/edit",
				component: lazy(() => import("src/views/wash-type/WashTypeEditView")),
			},
			{
				path: THEME_ROUTES.list,
				component: lazy(() => import("src/views/themes/list-view")),
			},
			// {
			// 	path: THEME_ROUTES.create,
			// 	component: lazy(() =>
			// 		import('src/views/themes/edit-view')
			// 	)
			// },
			// {
			// 	path: THEME_ROUTES.edit,
			// 	component: lazy(() =>
			// 		import('src/views/themes/edit-view')
			// 	)
			// },
			// {
			// 	path: THEME_ROUTES.copy,
			// 	component: lazy(() =>
			// 		import('src/views/themes/edit-view')
			// 	)
			// },
			{
				path: THEME_ROUTES.form,
				component: lazy(() => import("src/views/themes/edit-view")),
			},
			{
				path: "/app/management/messages/list/:filter?/:filterLabel?",
				component: lazy(() => import("src/views/messages/MessagesListView")),
			},
			{
				path: "/app/management/messages/create",
				component: lazy(() => import("src/views/messages/MessagesEditView")),
			},
			{
				path: "/app/management/messages/edit/:messageId/:copy?",
				component: lazy(() => import("src/views/messages/MessagesEditView")),
			},
			{
				path: "/app/management/configurations/acknowledgments",
				component: lazy(
					() => import("src/views/acknowledgments/AcknowledgmentsListView"),
				),
			},
			{
				path: "/app/management/configurations/acknowledgments/create",
				component: lazy(
					() => import("src/views/acknowledgments/AcknowledgmentsEditView"),
				),
			},
			{
				path: "/app/management/configurations/acknowledgments/:acknowledgmentId/edit",
				component: lazy(
					() => import("src/views/acknowledgments/AcknowledgmentsEditView"),
				),
			},
			{
				path: "/app/management/configurations/terms-and-help-links",
				component: lazy(
					() =>
						import("src/views/terms-and-help-links/TermsAndHelpLinksListView"),
				),
			},
			{
				path: "/app/management/configurations/terms-and-help-links/create",
				component: lazy(
					() =>
						import("src/views/terms-and-help-links/TermsAndHelpLinksEditView"),
				),
			},
			{
				path: "/app/management/configurations/terms-and-help-links/:termsAndHelpLinkId/edit",
				component: lazy(
					() =>
						import("src/views/terms-and-help-links/TermsAndHelpLinksEditView"),
				),
			},
			{
				path: GamesRoutes.ARCADE_LIST_ROUTE,
				component: lazy(() => import("src/views/games/arcade/ArcadeListView")),
			},
			{
				path: GamesRoutes.ARCADE_CREATE_ROUTE,
				component: lazy(() => import("src/views/games/arcade/ArcadeEditView")),
			},
			{
				path: GamesRoutes.ARCADE_EDIT_ROUTE,
				component: lazy(() => import("src/views/games/arcade/ArcadeEditView")),
			},
			{
				path: GamesRoutes.SPINNER_LIST_ROUTE,
				component: lazy(
					() => import("src/views/games/spinner/SpinnerListView"),
				),
			},
			{
				path: `${GamesRoutes.SPINNER_FORM_ROUTE}/*`,
				component: lazy(
					() => import("src/views/games/spinner/SpinnerEditView"),
				),
			},
			{
				path: "/app/management/configurations/dynamic-text",
				component: lazy(
					() => import("src/views/configurations/ConfigurationListView"),
				),
			},
			{
				path: "/app/management/configurations/dynamic-text/create",
				component: lazy(
					() => import("src/views/configurations/ConfigurationEditView"),
				),
			},
			{
				path: "/app/management/configurations/dynamic-text/:id/edit",
				component: lazy(
					() => import("src/views/configurations/ConfigurationEditView"),
				),
			},
			{
				path: CouponRoutes.LIST_ROUTE,
				component: lazy(() => import("src/views/coupon-management")),
			},
			{
				path: CouponRoutes.EDIT_ROUTE,
				component: lazy(() => import("src/views/coupons/CouponsEditView")),
			},
			{
				path: CouponRoutes.CREATE_ROUTE,
				component: lazy(() => import("src/views/coupons/CouponsEditView")),
			},
			{
				path: PromoCodeRoutes.EDIT_ROUTE,
				component: lazy(() => import("src/views/promo-codes/PromoCodeEditView")),
			},
			{
				path: PromoCodeRoutes.CREATE_ROUTE,
				component: lazy(() => import("src/views/promo-codes/PromoCodeEditView")),
			},
			{
				path: CouponGroupRoutes.EDIT_ROUTE,
				component: lazy(() => import("src/views/coupon-management/coupon-groups/coupon-group-edit-view")),
			},
			{
				path: CouponGroupRoutes.CREATE_ROUTE,
				component: lazy(() => import("src/views/coupon-management/coupon-groups/coupon-group-edit-view")),
			},
			{
				path: GiftCardRoutes.BASE_ROUTE,
				component: lazy(() => import("src/views/gift-cards")),
			},
			{
				path: GiftCardRoutes.PROMOTION_FORM_ROUTE,
				component: lazy(
					() => import("src/views/gift-cards/GiftCardPromotionsEditView"),
				),
			},
			{
				path: GiftCardRoutes.TRANSACTION_DETAILS_ROUTE,
				component: lazy(
					() => import("src/views/gift-cards/GiftCardTransactionDetails"),
				),
			},
			{
				path: RetentionOfferRoutes.FORM_ROUTE,
				component: lazy(
					() => import("src/views/retention-offers/RetentionOfferEditView"),
				),
			},
			{
				path: RetentionOfferRoutes.LIST_ROUTE,
				component: lazy(
					() => import("src/views/retention-offers/RetentionOfferListView"),
				),
			},
			{
				path: plans.PORTAL_PATH,
				component: lazy(() => import("src/views/plans/PlanListView")),
			},
			{
				path: plans.PORTAL_EDIT_PATH,
				component: lazy(() => import("src/views/plans/PlanEditView")),
			},
			{
				path: plans.PORTAL_EDIT_PATH + "/:id",
				component: lazy(() => import("src/views/plans/PlanEditView")),
			},
			{
				path: AddOnService.PORTAL_URL,
				component: lazy(() => import("src/views/add-ons/AddOnListView")),
			},
			{
				path: AddOnService.PORTAL_URL_CREATE,
				component: lazy(() => import("src/views/add-ons/AddOnEditView")),
			},
			{
				path: AddOnService.PORTAL_URL_EDIT,
				component: lazy(() => import("src/views/add-ons/AddOnEditView")),
			},
			{
				path: CodeBucketService.PORTAL_URL,
				component: lazy(
					() => import("src/views/code-buckets/CodeBucketListView"),
				),
			},
			{
				path: CodeBucketService.PORTAL_URL_CREATE,
				component: lazy(
					() => import("src/views/code-buckets/CodeBucketEditView"),
				),
			},
			{
				path: CodeBucketService.PORTAL_URL_EDIT,
				component: lazy(
					() => import("src/views/code-buckets/CodeBucketEditView"),
				),
			},
			{
				path: PriceGroupService.PORTAL_URL,
				component: lazy(
					() => import("src/views/price-groups/PriceGroupListView"),
				),
			},
			{
				path: PriceGroupService.PORTAL_URL_CREATE,
				component: lazy(
					() => import("src/views/price-groups/PriceGroupEditView"),
				),
			},
			{
				path: PriceGroupService.PORTAL_URL_EDIT,
				component: lazy(
					() => import("src/views/price-groups/PriceGroupEditView"),
				),
			},
			{
				path: IntegrationsRoutes.LIST_ROUTE,
				component: lazy(
					() => import("src/views/integrations/IntegrationsListView"),
				),
			},
			{
				path: IntegrationsRoutes.FORM_ROUTE_BY_KEY,
				component: lazy(
					() => import("src/views/integrations/IntegrationsEditView"),
				),
			},
			{
				path: IntegrationsRoutes.FORM_ROUTE_BY_KEY_AND_ID,
				component: lazy(
					() => import("src/views/integrations/IntegrationsEditView"),
				),
			},
			{
				path: "/app/management/invoice-history/:id/:limit/:strategy",
				component: lazy(() => import("src/views/user-management/Invoices")),
			},
			{
				path: WashModeRoutes.LIST_ROUTE,
				component: lazy(() => import("src/views/wash-mode/WashModeListView")),
			},
			{
				path: `${WashModeRoutes.FORM_ROUTE}/*`,
				component: lazy(() => import("src/views/wash-mode/WashModeEditView")),
			},
			{
				path: LoyaltyRoutes.BASE_ROUTE,
				component: lazy(() => import("src/views/loyalty/LoyaltyEditForm")),
			},
			{
				path: `${LoyaltyRoutes.FORM_ROUTE}/*`,
				component: lazy(
					() =>
						import(
							"src/views/loyalty/LoyaltyEditForm/LoyaltyRewardTiersEditView"
						),
				),
			},
			{
				path: ContactRoutes.BASE_ROUTE,
				component: lazy(() => import("src/views/contacts/ContactsListView")),
			},
			{
				path: ContactRoutes.EDIT_ROUTE,
				component: lazy(() => import("src/views/contacts/ContactsEditView")),
			},
			{
				path: ContactRoutes.CREATE_ROUTE,
				component: lazy(() => import("src/views/contacts/ContactsEditView")),
			},
			{
				path: EdgeDeviceRoutes.LIST_ROUTE,
				component: lazy(
					() => import("src/views/edge-device/EdgeDeviceListView"),
				),
			},
			{
				path: EdgeDeviceRoutes.FORM_EDGE_DEVICE_ROUTE,
				component: lazy(
					() => import("src/views/edge-device/EdgeDeviceEditView"),
				),
			},
			{
				path: ScanResultsRoutes.LIST_ROUTE,
				component: lazy(
					() => import("src/views/scan-results/ScanResultsListView"),
				),
			},
			{
				path: `${ScanResultsRoutes.FORM_ROUTE}/*`,
				component: lazy(
					() => import("src/views/scan-results/ScanResultsEditView"),
				),
			},
			{
				path: RfidReaderRoutes.LIST_ROUTE,
				component: lazy(
					() => import("src/views/rfid-readers/RfidReaderListView"),
				),
			},
			{
				path: `${RfidReaderRoutes.FORM_ROUTE}/*`,
				component: lazy(
					() => import("src/views/rfid-readers/RfidReaderEditView"),
				),
			},
			{
				path: "/app/management",
				component: () => <Navigate to="/app/management/dashboard" />,
			},
			{
				path: "/app",
				component: () => <Navigate to="/app/management/dashboard" />,
			},
			{
				path: "*",
				component: () => <Navigate to="/app/management/dashboard" />,
			},
		],
	},
	{
		path: "/fleet/*",
		guard: [AuthGuard, FleetGuard, FleetAdminPortal],
		layout: DashboardLayout,
		layoutProps: { navSections: fleetAdminSections },
		routes: [
			{
				path: "/fleet/usage-history/",
				component: lazy(() => import("src/views/fleet-admin/usage-history")),
			},
			{
				path: "/fleet/account-info",
				component: lazy(
					() => import("src/views/fleet-admin/fleet-account-info"),
				),
			},
			{
				path: "/fleet/manage-users",
				component: lazy(() => import("src/views/fleet-admin/manage-users")),
			},
			{
				path: "/fleet/manage-users/edit/:userExtId",
				component: lazy(
					() =>
						import("src/views/fleet-admin/manage-users/ManageUsersEditView"),
				),
			},
			{
				path: "/fleet/manage-users/create",
				component: lazy(
					() => import("src/views/fleet-admin/manage-users/ManageUsersAddView"),
				),
			},
			{
				path: "/fleet/manage-vehicles",
				component: lazy(() => import("src/views/fleet-admin/vehicles")),
			},
			{
				path: "*",
				component: () => <Navigate to="account-info" />,
			},
		],
	},
	{
		path: "/site-lead/*",
		guard: [AuthGuard, SiteLeadGuard],
		layout: DashboardLayout,
		layoutProps: { navSections: siteLeadSections },
		routes: [
			{
				path: SITE_LEAD_USERS_BASE_ROUTE,
				component: lazy(() => import("src/views/user-management/UserListView")),
			},
			{
				path: SITE_LEAD_USERS_EDIT_ROUTE,
				component: lazy(() => import("src/views/user-management/UserEditView")),
			},
			{
				path: SITE_LEAD_EMAIL_MIGRATION_ROUTE,
				component: lazy(
					() => import("src/views/user-management/MigrateUserEmails"),
				),
			},
			{
				path: SITE_LEAD_GUEST_USER_ACTIVITY_ROUTE,
				component: lazy(
					() =>
						import("src/views/guest-user-activity/GuestUserActivityListView"),
				),
			},
			{
				path: SITE_LEAD_KIOSK_TRANSACTIONS_ROUTE,
				component: lazy(
					() =>
						import("src/views/kiosk-transactions/KioskTransactionsListView"),
				),
			},
			{
				path: SITE_LEAD_ALL_TRANSACTIONS_ROUTE,
				component: lazy(
					() => import("src/views/transactions/TransactionsListView"),
				),
			},
			{
				path: "*",
				component: () => <Navigate to={SITE_LEAD_USERS_BASE_ROUTE} />,
			},
		],
	},
	{
		path: "*",
		component: function NavigateToLogin() {
			const { search } = useLocation();
			return <Navigate to={{ pathname: "/login", search }} />;
		},
	},
];
