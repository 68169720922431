import { EFormType } from "src/enums/form-type.enum";
import { ENavBaseRoute } from "src/enums/nav-base-route.enum";
import { ERouteParams } from "src/enums/route-param.enums";
import { CouponRoutes } from "../coupon-management/coupons.routes";
import { ECouponManagementTabKey } from "../coupon-management";

const BASE_ROUTE = `/${ENavBaseRoute.management}/promo-codes`;

const CREATE_ROUTE = `${BASE_ROUTE}/${EFormType.create}`;
const EDIT_ROUTE = `${BASE_ROUTE}/${EFormType.edit}/:id`;
const TAB_ROUTE = `${CouponRoutes.BASE_ROUTE}${ERouteParams.Tab}${ECouponManagementTabKey.PromoCodes}`;

export const PromoCodeRoutes = {
	BASE_ROUTE,
	EDIT_ROUTE,
	CREATE_ROUTE,
	TAB_ROUTE,
};
