import React, { FC } from "react";
import { combineComponents } from "../utils/combineComponents";

export const withProviders = (...providers: FC<any>[]) => {
	const Provider = combineComponents(...providers);
	return <TProps extends {}>(WrappedComponent: FC<TProps>) => (
		props: TProps,
	) => (
		<Provider>
			<WrappedComponent {...props} />
		</Provider>
	);
};
